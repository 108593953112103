import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const ProtectedDashboardRoutes = ({ children, ...rest }) => {
  const doctorInfo = JSON.parse(localStorage.getItem("Doctor"));
  const isAuth = doctorInfo;
  return (
    <Route
      {...rest}
      render={() => (isAuth ? children : <Redirect to={"/dashboard/login"} />)}
    />
  );
};

ProtectedDashboardRoutes.propTypes = {
  children: PropTypes.any,
};
export default ProtectedDashboardRoutes;
