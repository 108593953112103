import axios from "axios";
import logo from "./physifit25.png";
import "./SignIn.css";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../redux/Action";
import Cookie from "js-cookie";
import axiosInstance from "../CustomAxios";

const Login = () => {
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("Profile"));
    const doctor = JSON.parse(localStorage.getItem("Doctor"));
    if (user !== null || doctor !== null) {
      history.goBack();
    }
  }, []);
  const dispatch = useDispatch();
  const history = useHistory();
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = async () => {
    try {
      const req = {
        id,
        password,
      };
      const res = await axios.post("/api/doctors/login", req);
      if (res.status !== 200) {
        alert("Error while Logging In!");
        return;
      }
      dispatch(setAccessToken(res.headers.authorization));
      Cookie.set("refresh", res.headers.refreshtoken);
      const doctor = await axiosInstance.get(`/api/doctors/${id}`);
      const doctorInfo = {
        doctorData: doctor.data.data,
        headers: { authorization: res.headers.authorization },
      };
      localStorage.setItem("Doctor", JSON.stringify(doctorInfo));
      history.push("/dashboard?patients");
    } catch (err) {
      alert("Error while Logging In!");
      console.log(err);
    }
  };
  return (
    <div className="maincontainer">
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-md-6 d-none d-md-flex bg-image"></div>
          <div className="col-md-6 bg-light">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="col Logo">
                  <img src={logo} alt="logo" />
                </div>
                <div className="row">
                  <div className="col-lg-10 col-xl-8 mx-auto">
                    <p className="mb-4 mt-3">
                      Welcome back! Please login to your account.
                    </p>
                    <div className="mb-3">
                      <input
                        type="number"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        placeholder="Mobile Number"
                        required
                        autoFocus
                        className="form-control rounded-pill border-0 shadow-sm px-4"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                        className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                      />
                    </div>
                    <div className="row gap-4 mt-4">
                      <button
                        htmlFor="inputEmail4"
                        className="col btn btn-primary btn-block text-uppercase m-2 shadow-sm"
                        onClick={handleSubmit}
                      >
                        Login
                      </button>
                      <button
                        type="submit"
                        className="col btn btn-primary btn-block text-uppercase m-2 shadow-sm"
                        onClick={handleSubmit}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
