import { Stack, Button } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";

const TopNav = () => {
  const history = useHistory();
  const doctor = localStorage.getItem("Doctor");
  const { doctorData } = JSON.parse(doctor);
  const handleProfile = () => {
    history.push({
      pathname: `/doctor/profile/${doctorData.id}`,
      state: {
        id: doctorData.id,
      },
    });
  };
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      paddingRight="1rem"
      boxShadow="1px 2px 15px rgba(0,0,0,0.1)"
      backgroundColor="white"
    >
      <Link
        to="/"
        style={{
          margin: "0.5rem 2rem",
          textDecoration: "none",
        }}
      >
        <img
          src="https://storage.googleapis.com/staging-338019/physifit-ui/assets/website-logo.png"
          alt="physifit-logo"
          style={{ width: "40%" }}
        />
      </Link>
      <Button onClick={handleProfile} style={{ color: "black" }}>
        <FaUserCircle
          style={{ fontSize: "30px", marginRight: "10px" }}
        ></FaUserCircle>
        <IoIosArrowUp style={{ fontSize: "20px" }}></IoIosArrowUp>
      </Button>
    </Stack>
  );
};

export default TopNav;
