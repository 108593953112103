const images = {
  process1: require("./process1.png").default,
  process2: require("./process2.png").default,
  process3: require("./process3.png").default,
  process4: require("./process4.png").default,
  arrow: require("./arrow.png").default,
  doctor1: require("./Person1.jpg").default,
  doctor2: require("./Person2.jpg").default,
  doctor3: require("./Person3.jpg").default,
  doctor4: require("./Person4.jpg").default,
  doctor5: require("./Person5.jpg").default,
  chooseBooking: require("./choose1.png").default,
  choosePick: require("./choose2.png").default,
  chooseSecure: require("./choose3.png").default,
  groupDoctor: require("./Group_Doctors.png").default,
  goldMedal: require("./Gold_medal.png").default,
  groupPatient: require("./Group_Patients.png").default,
  reliabe: require("./Reliable.png").default,
  transparency: require("./Transparency.png").default,
  homeTreatment: require("./Home_Treatment.png").default,
  sportsIcon: require("./Sports_Related_Icon.png").default,
  workstationIcon: require("./Workstation Icon.png").default,
  virtualIcon: require("./Virtual_Physiotherapy.png").default,
  groupIcon: require("./Group_Therapy.png").default,
  surgeryIcon: require("./Pre_Post_Surgery.png").default,
  acuteIcon: require("./Acute_and_Chronic.png").default,
  city: require("./Cities.png").default,
  appointmentIcon: require("./dashboardAppointmentsIcon.png").default,
  couponLeft: require("./dashboardCouponsLeft.png").default,
  couponRight: require("./dashboardCouponsRight.png").default,
  patientIcon: require("./dashboardPatientsIcon.png").default,
  mobileVerification1: require("./Mobile_Phone_Verification_1.png").default,
  mobileVerification2: require("./Mobile_Phone_Verification_2.png").default,
};

export default images;
