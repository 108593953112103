import { lazy, Suspense, Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/Login";
import ProtectedUserRoutes from "./ProtectedUserRoutes";
import ProtectedDashboardRoutes from "./ProtectedDashboardRoutes";
import MainPage from "./main-ui/MainPage";
import Confirmation from "./booking-page/Confirmation";
import PaymentSuccess from "./booking-page/PaymentSuccess";
import PaymentFailed from "./booking-page/PaymentFailed";
import BookingPhysio from "./booking-physio/BookingPhysio";
import ConfirmPhysio from "./booking-physio/ConfirmPhysio";
import ThankYouPage from "./thank-you/ThankYouPage";
import PaymentSuccessPhysio from "./booking-physio/PaymentSuccessPhysio";
import DashboardLanding from "./dashboard/DashboardLanding";
import ContactUs from "./contact-us/ContactUs";
import ErrorPage from "./components/ErrorPage";
import FaqCollapse from "./main-ui/faq-collapse/FaqCollapse";
import BulkBookingPage from "./booking-page/BulkBookingPage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Slide, Stack } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import DoctorProfile from "./doctor-profile/DoctorProfile";
import Payout from "./payout/Payout";
import TransactionDetails from "./payout/TransactionDetails";

const Blog = lazy(() => import("./blog-pages/blog/Blog"));
const BlogHome = lazy(() => import("./blog-pages/blog-home/BlogHome"));
const About = lazy(() => import("./about-page/About"));
const LandingPage = lazy(() =>
  import("./main-ui/conditions-we-treat/LandingPage")
);

const PatientDashboard = lazy(() =>
  import("./patient-profile/PatientDashboard")
);
const PatientOrders = lazy(() => import("./patient-profile/PatientOrders"));
const PatientPrescription = lazy(() =>
  import("./patient-profile/PatientPrescription")
);
const MyProfile = lazy(() => import("./patient-profile/MyProfile"));
const ManageBookings = lazy(() => import("./patient-profile/ManageBookings"));
const PatientLogin = lazy(() => import("./patient-profile/PatientLogin"));

const TermsAndConditions = lazy(() =>
  import("./terms-and-conditions/TermsAndConditions")
);
const ComingSoon = lazy(() => import("./ComingSoon"));

export default class App extends Component {
  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <div>
            {/* <Favicon url={base64Url} /> */}
            <Suspense
              fallback={
                <Slide direction="down" in>
                  <Stack
                    width={{ xs: "100%", sm: "100%", md: "90%" }}
                    justifyContent="center"
                  >
                    <RotateLeftIcon
                      sx={{
                        fontSize: "3rem",
                        margin: "0 auto",
                        color: "rgba(0,0,0,0.6)",
                        animation: "spin 2s linear infinite",
                        "@keyframes spin": {
                          "0%": {
                            transform: "rotate(360deg)",
                          },
                          "100%": {
                            transform: "rotate(0deg)",
                          },
                        },
                      }}
                    />
                  </Stack>
                </Slide>
              }
            >
              <Switch>
                <Route path="/" exact={true} component={MainPage} />
                <Route path="/dashboard/login" component={Login} />
                <Route path="/booking" component={BulkBookingPage} />
                <Route path="/thankyou" component={ThankYouPage} />
                <Route path="/confirmbooking" component={Confirmation} />
                <Route path="/paymentsuccess" component={PaymentSuccess} />
                <Route path="/faqs" component={FaqCollapse} />
                <Route
                  path="/paymentsuccessphysio"
                  component={PaymentSuccessPhysio}
                />
                <Route path="/paymentfailed" component={PaymentFailed} />
                <Route path="/bookphysio" component={BookingPhysio} />
                <Route path="/confirmbookingphysio" component={ConfirmPhysio} />
                <ProtectedDashboardRoutes path="/dashboard">
                  <DashboardLanding />
                </ProtectedDashboardRoutes>
                <ProtectedDashboardRoutes path="/user/bookings/:id">
                  <ManageBookings />
                </ProtectedDashboardRoutes>
                <Route path="/user/login" component={PatientLogin} />
                <ProtectedUserRoutes path="/user/dashboard/:id">
                  <PatientDashboard />
                </ProtectedUserRoutes>
                <ProtectedUserRoutes path="/user/profile/:id">
                  <MyProfile />
                </ProtectedUserRoutes>
                <ProtectedUserRoutes path="/doctor/profile/:id">
                  <DoctorProfile />
                </ProtectedUserRoutes>
                <ProtectedUserRoutes path="/doctor/:id/payout">
                  <Payout />
                </ProtectedUserRoutes>
                <ProtectedUserRoutes path="/doctor/payout/payment">
                  <TransactionDetails />
                </ProtectedUserRoutes>
                <ProtectedUserRoutes path="/user/orders/:id">
                  <PatientOrders />
                </ProtectedUserRoutes>
                <ProtectedUserRoutes path="/user/prescription/:id">
                  <PatientPrescription />
                </ProtectedUserRoutes>
                <Route path="/about" component={About} />
                <Route path="/contact" component={ContactUs} />
                <Route exact path="/blog" component={BlogHome} />
                <Route path="/blog/:id" component={Blog} />
                <Route
                  exact
                  path="/conditions-we-treat"
                  component={LandingPage}
                />
                <Route
                  path="/conditions-we-treat/:id"
                  component={LandingPage}
                />
                <Route path="/comingsoon" component={ComingSoon} />
                <Route path="/terms" component={TermsAndConditions} />
                <Route path="/c/:id" component={BookingPhysio} />
                <Route path="*" component={ErrorPage} />
              </Switch>
            </Suspense>
          </div>
        </BrowserRouter>
      </LocalizationProvider>
    );
  }
}
