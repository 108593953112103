import PropTypes from "prop-types";
import {
  Stack,
  TextField,
  Typography,
  InputAdornment,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import axiosInstance from "../CustomAxios";

const CouponUpdate = (coupon) => {
  const [discount, setDiscount] = useState(coupon.discount);
  const [startDate, setStartDate] = useState(coupon.start);
  const [endDate, setEndDate] = useState(coupon.end);
  const [loading, setLoading] = useState(false);
  const handleUpdate = async () => {
    if (discount === "") {
      alert("Empty Discount Percentage field");
      return;
    } else if (discount <= 0 || discount > 100) {
      alert("Enter Valid Discount Percentage");
      return;
    } else if (startDate === "") {
      alert("Empty Coupon's Start Date");
      return;
    } else if (endDate === "") {
      alert("Empty Coupon's End Date");
      return;
    } else if (startDate > endDate) {
      alert("Start date cannot be after end date!");
      return;
    }
    setLoading(true);
    const newCoupon = {
      coupon_code: coupon.couponCode,
      discount_percentage: discount,
      start_date: startDate,
      start_time: "00:00:00",
      end_date: endDate,
      end_time: "23:59:59",
    };
    try {
      await axiosInstance.put("/api/dashboard/coupons", newCoupon);
      alert("Coupon Updated Successfully");
      coupon.handleChange("view");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    coupon.setEdit(false);
  };
  const handleReset = () => {
    setDiscount(coupon.discount);
    setStartDate(coupon.start);
    setEndDate(coupon.end);
  };
  return (
    <Stack padding="2% 5%" spacing={4}>
      <Typography variant="body1" textAlign="center" color="rgba(0,0,0,0.6)">
        Update Coupon
      </Typography>
      <Stack spacing={3}>
        <TextField
          variant="outlined"
          style={{ backgroundColor: "azure" }}
          InputProps={{ readOnly: true }}
          label="Coupon Code"
          value={coupon.couponCode}
        ></TextField>
        <TextField
          variant="outlined"
          style={{ backgroundColor: "azure" }}
          label="Discount Percentage"
          value={discount}
          type="number"
          onChange={(e) => setDiscount(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        ></TextField>
        <TextField
          variant="outlined"
          style={{ backgroundColor: "azure" }}
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        ></TextField>
        <TextField
          variant="outlined"
          style={{ backgroundColor: "azure" }}
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        ></TextField>
        <Stack paddingTop="1rem" direction="row" justifyContent="space-evenly">
          <LoadingButton
            loading={loading}
            variant="contained"
            style={{ width: "fit-content" }}
            onClick={handleUpdate}
          >
            Update
          </LoadingButton>
          <Button
            variant="contained"
            style={{ width: "fit-content" }}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            style={{ width: "fit-content" }}
            onClick={() => coupon.setEdit(false)}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
CouponUpdate.propTypes = {
  location: PropTypes.object,
};
export default CouponUpdate;
