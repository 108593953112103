import Slider from "react-slick";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import doctorData from "./Info";
import "./InfoCarousel.css";
import DoctorInfoCard from "../doctor-info-card/DoctorInfoCard";

export default function InfoCarousel() {
  const settings = {
    className: "center infoCarousel",
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {doctorData.map((props) => (
        <DoctorInfoCard
          key={props.id}
          name={props.name}
          url={props.url}
          bio={props.bio}
        />
      ))}
    </Slider>
  );
}

InfoCarousel.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  url: PropTypes.string,
  bio: PropTypes.array,
};
