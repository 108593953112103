import { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../main-ui/footer/Footer";
import MobileFooter from "../main-ui/footer/MobileFooter";
import NavBar from "../main-ui/nav-bar/NavBar";
import "./ErrorPage.css";

export default class ErrorPage extends Component {
  render() {
    return (
      <>
        <NavBar />
        <div className="main-content">
          <div className="error">
            <>404</>
          </div>
          <div className="not-found">
            <>Page Not Found</>
          </div>
          <div className="go-back">
            <p>
              The page you requested could not be found <br />
              Try going back to home page
            </p>
            <br />
          </div>
          <Link to="/">
            <button className="home-button">Go to Home</button>
          </Link>
        </div>
        <div className="desktop-footer">
          <Footer />
        </div>
        <div className="mobile-footer">
          <MobileFooter />
        </div>
      </>
    );
  }
}
