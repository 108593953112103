import { Component } from "react";

import NavBar from "./nav-bar/NavBar";
import MainContent from "./main-content/MainContent";

export default class MainPage extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <MainContent />
      </div>
    );
  }
}
