import PropTypes from "prop-types";
import "./ServiceCard.css";

function ServiceCard(props) {
  return (
    <>
      <div className="service-card">
        <div className="service-card-body">
          <p className="card-title font-change">{props.alt}</p>
        </div>
        <div className="service-card-img-container">
          <img className="service-card-img" src={props.src} alt={props.alt} />
        </div>
      </div>
    </>
  );
}

ServiceCard.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
};
export default ServiceCard;
