import "./MobileFooter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customerCareNumber } from "../../utils";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

const MobileFooter = () => {
  return (
    <div>
      <div className="mfooter-main-container">
        <div className="mfooter-sub-container">
          <div className="mfooter-container">
            <div className="mfooter-header">
              <h1>Physi Fit</h1>
              <p> Learn Today to be your strongest self with Physiotherapy.</p>
            </div>
            <div className="mfooter-lists">
              <a href="/about">About Us</a>
              {/* <a href="#testimonials">Our Customer</a> */}
              {/* <a href="/terms#privacy">Privacy Policy</a> */}
              <a href="/faqs">FAQs</a>
              <a href="/contact">Help & Support</a>
              <a href="/terms">Terms & Conditions</a>
              <a href={`tel:+91${customerCareNumber}`}> {customerCareNumber}</a>
            </div>
          </div>
        </div>
        <div className="mfooter-sub-container">
          <div className="mfooter-social">
            <a
              href="https://www.facebook.com/org.physifit"
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
            >
              <FontAwesomeIcon size="1x" icon={faFacebookF} />
            </a>
            <a href="https://twitter.com/physifitorg">
              <FontAwesomeIcon size="1x" icon={faTwitter} />
            </a>
            <a href={`http://wa.me/${customerCareNumber}`}>
              <FontAwesomeIcon size="1x" icon={faWhatsapp} />
            </a>
            <a href="https://www.instagram.com/physifitorg/">
              <FontAwesomeIcon size="1x" icon={faInstagram} />
            </a>
            <a href="https://www.linkedin.com/company/physifit/about/">
              <FontAwesomeIcon size="1x" icon={faLinkedinIn} />
            </a>
          </div>
        </div>

        <div className="mfooter-sub-container">
          <p className="mfooter-copyright">
            <FontAwesomeIcon
              size="1x"
              icon={faCopyright}
              style={{ margin: "0px 10px 0px 0px" }}
            />
            2022 all rights reserverd
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;
