import {
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axiosInstance from "../CustomAxios";
import { useState } from "react";
import { Link } from "react-router-dom";

const DashboardPatients = () => {
  const [value, setValue] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const handleChange = (value) => {
    setValue(value);
    setPatients([]);
  };
  const handleSubmit = async () => {
    if (value === "name") {
      if (name === "") {
        alert("Enter the name before submitting!");
        return;
      }
      console.log(name);
      setLoading(true);
      try {
        // const searchDetails = { name };
        const res = await axiosInstance.get(`/api/users?name=${name}`);
        console.log(res.data.data);
        if (res.data.data === null) {
          alert("No patient with this name");
          setLoading(false);
          setName("");
          return;
        }

        setPatients(res.data.data);
        console.log(patients);
        setName("");
      } catch (error) {
        setPatients([]);
        alert("Error while searching");
        console.log(error);
      }
      setLoading(false);
    } else if (value === "id") {
      if (id === "") {
        alert("Enter the name before submitting!");
        return;
      }
      setLoading(true);
      try {
        const res = await axiosInstance.get(`/api/users/${id}`);
        console.log(res.data);
        setPatients([res.data.data]);
      } catch (error) {
        setPatients([]);
        alert(error.response.data.message);
        console.log(error);
      }
      setLoading(false);
    } else if (value === "date") {
      if (start === "") {
        alert("Enter the start date before submitting!");
        return;
      } else if (end === "") {
        alert("Enter the end date before submitting!");
        return;
      } else if (start > end) {
        alert("Start date cannot be after end date!");
        return;
      }
      setLoading(true);
      try {
        const searchDetails = { from_date: start, to_date: end };
        const res = await axiosInstance.post(
          "/api/dashboard/search",
          searchDetails
        );
        setPatients(res.data.data);
      } catch (error) {
        setPatients([]);
        alert("Error while searching");
        console.log(error);
      }
      setLoading(false);
    }
  };
  return (
    <Stack direction="column" spacing={2} paddingBottom="2rem">
      <Typography variant="h5" style={{ marginTop: "2rem" }} textAlign="center">
        Search Patients
      </Typography>
      <Stack
        width={{ xs: "80%", sm: "80%", md: "100%" }}
        paddingLeft={{ xs: "5rem", sm: "5rem", md: "0" }}
      >
        <FormControl>
          <RadioGroup
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            row
          >
            <FormControlLabel
              sx={{ paddingRight: { xs: "10px", sm: "10px", md: "30px" } }}
              control={<Radio />}
              label="By Patient Name"
              value="name"
              style={{
                color: value === "name" ? "#1983D8" : "rgba(0,0,0,0.6)",
              }}
            />
            <FormControlLabel
              sx={{ paddingRight: { xs: "10px", sm: "10px", md: "30px" } }}
              control={<Radio />}
              label="By Patient ID"
              value="id"
              style={{
                color: value === "id" ? "#1983D8" : "rgba(0,0,0,0.6)",
              }}
            />
            <FormControlLabel
              control={<Radio />}
              label="By Date Range"
              value="date"
              style={{
                color: value === "date" ? "#1983D8" : "rgba(0,0,0,0.6)",
              }}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      {value === "name" ? (
        <TextField
          variant="outlined"
          style={{
            backgroundColor: "azure",
            width: "80%",
            margin: "1rem auto 0",
          }}
          label="Enter Patient Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></TextField>
      ) : value === "id" ? (
        <TextField
          variant="outlined"
          style={{
            backgroundColor: "azure",
            width: "80%",
            margin: "1rem auto 0",
          }}
          label="Enter Patient ID"
          type="number"
          value={id}
          onChange={(e) => setId(e.target.value)}
        ></TextField>
      ) : value === "date" ? (
        <Stack spacing={3}>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "azure",
              width: "80%",
              margin: "1rem auto 2rem",
            }}
            label="Enter Start Date"
            type="date"
            value={start}
            onChange={(e) => setStart(e.target.value)}
            focused
          ></TextField>
          <TextField
            variant="outlined"
            style={{
              backgroundColor: "azure",
              width: "80%",
              margin: "0 auto",
            }}
            label="Enter End Date"
            type="date"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
            focused
          ></TextField>
        </Stack>
      ) : (
        <></>
      )}
      {value !== "" && (
        <LoadingButton
          loading={loading}
          variant="contained"
          style={{ width: "fit-content", margin: "2rem auto" }}
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      )}
      {patients !== null && patients?.length !== 0 && (
        <Stack
          width="100%"
          position={{
            xs: "absolute",
            sm: "absolute",
            md: "relative",
          }}
          padding={{
            xs: "21rem 5% 2rem",
            sm: "21rem 5% 2rem",
            md: "0",
          }}
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {/* <TableCell style={{ fontSize: "16px" }}>Sl. No.</TableCell> */}
                    <TableCell style={{ fontSize: "16px" }}>Name</TableCell>
                    <TableCell style={{ fontSize: "16px" }}>Mobile</TableCell>
                    <TableCell style={{ fontSize: "16px" }}>Email ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patients?.map((patient, index) => (
                    <TableRow key={patient?.id}>
                      {/* <TableCell>{index + 1}</TableCell> */}
                      <TableCell>{patient?.name}</TableCell>
                      <TableCell>{patient?.mobile}</TableCell>
                      <TableCell>{patient?.email}</TableCell>
                      <TableCell>
                        <Link to={`/user/dashboard/${patient?.id}`}>
                          <Button
                            variant="contained"
                            style={{ width: "fit-content", margin: "0 auto" }}
                          >
                            <VisibilityIcon
                              style={{ marginRight: "0.5rem" }}
                            ></VisibilityIcon>
                            View
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* <TableBody>
                  <TableRow key={patients?.id}>
                    <TableCell>{patients?.name}</TableCell>
                    <TableCell>{patients?.mobile}</TableCell>
                    <TableCell>{patients?.email}</TableCell>
                    <TableCell>
                      <Link to={`/user/dashboard/${patients?.id}`}>
                        <Button
                          variant="contained"
                          style={{ width: "fit-content", margin: "0 auto" }}
                        >
                          <VisibilityIcon
                            style={{ marginRight: "0.5rem" }}
                          ></VisibilityIcon>
                          View
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody> */}
              </Table>
            </TableContainer>
          </Paper>
        </Stack>
      )}
    </Stack>
  );
};

export default DashboardPatients;
