import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import axiosInstance from "../CustomAxios";
import { useState } from "react";
import { faSpinner, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import NavBar from "../main-ui/nav-bar/NavBar";
import Footer from "../main-ui/footer/Footer";
import MobileFooter from "../main-ui/footer/MobileFooter";
import arrow from "../images/arrow2.png";
import "./Confirmation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bucket } from "./../utils";
import DeleteIcon from "@mui/icons-material/Delete";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
const __ISPROD__ =
  window.location.hostname === "physifit.org" ||
  window.location.hostname === "www.physifit.org";

function Confirmation(props) {
  const bookingDetails = props.location.state.bookingDetails;
  const [price, setPrice] = useState(
    bookingDetails.price * props.location.state.quantity
  );
  const [promo, setPromo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [applyLoad, setApplyLoad] = useState(false);
  const history = useHistory();
  const handlePromo = async () => {
    try {
      setApplyLoad(true);
      const res = await axiosInstance.get(
        `/api/dashboard/coupons?coupon_code=${promo.toUpperCase()}`
      );
      const { data } = res.data;
      if (!data || !res.data.data.active) {
        setPromoError("Invalid Coupon");
        setApplyLoad(false);
        return;
      }
      setIsApplied(true);
      setPrice(
        Math.round((price - (price * data.discount_percentage) / 100) * 100) /
          100
      );
      setPromoError("");
      setApplyLoad(false);
    } catch (error) {
      console.log(error);
      setPromoError("Invalid Coupon");
      setApplyLoad(false);
    }
  };
  const handleRemove = () => {
    setPrice(bookingDetails.price);
    setPromo("");
    setPromoError("");
    setIsApplied(false);
  };
  const handlePromoChange = (promoVal) => {
    setPromo(promoVal);
    if (promoVal === "") {
      handleRemove();
    }
  };
  const handleRazorPay = async () => {
    setIsLoading(true);
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      const req = {
        name: bookingDetails.name,
        email: bookingDetails.email,
        patient_id: bookingDetails.patient_id,
        quantity: props.location.state.quantity,
        price: parseFloat(price / props.location.state.quantity),
        description: bookingDetails.description,
      };

      const { data } = await axiosInstance
        .post("/api/orders", req)
        .catch((err) => {
          alert(err.response.data.message);
          console.log(err);
        });
      const User = await axiosInstance.get(
        `/api/users/${data.data.patient_id}`
      );
      localStorage.setItem("Profile", JSON.stringify(User));
      const options = {
        key: __ISPROD__ ? "rzp_live_7dZ4TX7Ab409fl" : "rzp_test_NseEJwKl04eSmh", // Enter the Key ID generated from the Dashboard
        amount: data.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: data.data.currency,
        name: "Physifit", // your business name
        description: "Booking Transaction",
        image:
          "https://storage.googleapis.com/" +
          bucket +
          "/physifit-ui/assets/Social-Media-Icon.png",
        callback_url: `/thankyou?email=${bookingDetails.email}`,
        order_id: data.data.id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        patient_id: data.data.patient_id,
        prefill: {
          name: bookingDetails.name,
          email: bookingDetails.email,
          contact: bookingDetails.patient_id,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", function (response) {
        history.push("/paymentfailed");
      });
      paymentObject.open();
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };
  return (
    <div>
      <NavBar />
      <section className="page-header">
        <div className="page-header-content">
          <img src={arrow} alt="arrow" className="page-header-img" />
          <span className="page-header-text">CONFIRM YOUR BOOKING</span>
        </div>
      </section>
      <div className="confirm-details">
        <div className="confirm-details-element">
          <p className="confirm-details-element-label">Name :</p>
          <p className="confirm-details-element-text">{bookingDetails.name}</p>
        </div>
        <div className="confirm-details-element">
          <p className="confirm-details-element-label">Email :</p>
          <p className="confirm-details-element-text">{bookingDetails.email}</p>
        </div>
        <div className="confirm-details-element">
          <p className="confirm-details-element-label">Mobile Number :</p>
          <p className="confirm-details-element-text">
            {bookingDetails.patient_id}
          </p>
        </div>
        <div className="confirm-details-element">
          <p className="confirm-details-element-label">Description :</p>
          <p className="confirm-details-element-text">
            {bookingDetails.description}
          </p>
        </div>
        <div className="confirm-details-element">
          <p className="confirm-details-element-label">Quantity :</p>
          <p className="confirm-details-element-text">
            {props.location.state.quantity}
          </p>
        </div>
        <div className="confirm-details-amount">
          <span className="booking-amt">
            <span className="booking-amt-label">Booking Amount :</span>
            <span className="booking-amt">
              <FontAwesomeIcon icon={faRupeeSign} />
              &nbsp;
              {isApplied ? (
                <>
                  <span
                    style={{ textDecoration: "line-through" }}
                    className="booking-amt-text"
                  >
                    {bookingDetails.price * props.location.state.quantity}
                  </span>
                  <span>
                    <strong>
                      &emsp;
                      {price}
                    </strong>
                  </span>
                </>
              ) : (
                <span className="booking-amt-text">
                  {bookingDetails.price * props.location.state.quantity}
                </span>
              )}
            </span>
          </span>
        </div>
        <section className="promo-code-box">
          <section className="promo-code">
            <label htmlFor="product" className="center-align">
              Apply Coupon Code :
            </label>
            <input
              id="promo-code-box"
              className="apply-promo-input"
              type="text"
              name="product"
              list="productName"
              value={promo}
              onChange={(e) => handlePromoChange(e.target.value.toUpperCase())}
            />
            {!applyLoad && promo !== "" && !isApplied && (
              <button
                className="btn-booking btn-booking-promo-validate bookphysio-desktop"
                onClick={handlePromo}
              >
                Apply
              </button>
            )}
            {!applyLoad && promo !== "" && !isApplied && (
              <button
                onClick={handlePromo}
                className="btn-booking btn-booking-promo-validate bookphysio-mobile"
              >
                Apply
              </button>
            )}
            {applyLoad && (
              <button className="btn-booking" disabled>
                <FontAwesomeIcon icon={faSpinner} className="icon-spinner" />
              </button>
            )}
            {isApplied && (
              <button
                onClick={handleRemove}
                style={{ width: "fit-content", marginLeft: "10px" }}
                className="btn-booking"
              >
                <DeleteIcon />
              </button>
            )}
          </section>
          {promoError !== "" && (
            <div className="text-danger font-weight-bold coupon-error">
              {promoError}
            </div>
          )}
          {isApplied && (
            <span style={{ color: "green" }}>Applied Successfully</span>
          )}
        </section>
        <div className="booknow-parent">
          <div className="booknow-child">
            {!isLoading && (
              <button
                onClick={handleRazorPay}
                type="submit"
                className="btn-booking"
              >
                CONFIRM BOOKING
              </button>
            )}
            {isLoading && (
              <button className="btn-booking" disabled>
                <FontAwesomeIcon icon={faSpinner} className="icon-spinner" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="desktop-footer">
        <Footer />
      </div>
      <div className="mobile-footer">
        <MobileFooter />
      </div>
      {/* <div className="d-flex justify-content-center">
            <button onClick={handlePayButton} className="btn btn-primary mt-5">
              Pay
            </button>
          </div> */}
    </div>
  );
}

Confirmation.propTypes = {
  location: PropTypes.object,
};

export default Confirmation;
