import Slider from "react-slick";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PatientTestimonials.css";
import TestimonialData from "./Info";
import TestimonialCard from "../testimonial-card/TestimonialCard";

export default function PatientTestimonials() {
  const settings = {
    className: "patient-testimonial-carousel",
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {TestimonialData.map((props) => (
        <TestimonialCard
          key={props.id}
          text={props.text}
          name={props.name}
          designation={props.designation}
          img={props.img}
        />
      ))}
    </Slider>
  );
}

PatientTestimonials.propTypes = {
  id: PropTypes.any,
  text: PropTypes.string,
  name: PropTypes.string,
  designation: PropTypes.string,
  img: PropTypes.string,
};
