import { bucket } from "./../../utils";
const data = [
  {
    id: 1,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition1.png",
    description: "Arthritic Pain",
    link: "/conditions-we-treat/arthritic-pain",
  },
  {
    id: 2,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition2.png",
    description: "Back Pain",
    link: "/conditions-we-treat/back-pain-and-scatica",
  },
  {
    id: 3,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition3.png",
    description: "Cardiopulmonary Conditions",
    link: "/conditions-we-treat/cardiopulmonary-conditions",
  },
  {
    id: 4,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition4.png",
    description: "Elbow Pain",
    link: "/conditions-we-treat/elbow-wrist-pain",
  },
  {
    id: 5,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition5.png",
    description: "Foot Pain",
    link: "/conditions-we-treat/foot-ankle-pain",
  },
  {
    id: 6,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition6.png",
    description: "Shoulder Pain",
    link: "/conditions-we-treat/shoulder-pain",
  },
  {
    id: 7,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition7.png",
    description: "Gait Disorders",
    link: "/conditions-we-treat/balance-disorders",
  },
  {
    id: 8,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition8.png",
    description: "Geriatric Disorders",
    link: "/conditions-we-treat/geriatric-conditions",
  },
  {
    id: 9,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition9.png",
    description: "Knee Pain",
    link: "/conditions-we-treat/hip-knee-pain",
  },
  {
    id: 10,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition10.png",
    description: "Neck Pain",
    link: "/conditions-we-treat/neck-pain",
  },
  {
    id: 11,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition11.png",
    description: "Neurological Conditions",
    link: "/conditions-we-treat/neurological-conditions",
  },
  {
    id: 12,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition12.png",
    description: "Post Traumatic Conditions",
    link: "/conditions-we-treat/post-traumatic-pain",
  },
  {
    id: 13,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition13.png",
    description: "Surgical Conditions",
    link: "/conditions-we-treat/pre-post-surgical",
  },
  {
    id: 14,
    condition:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/conditions/condition14.png",
    description: "Sport Related Disorders",
    link: "/conditions-we-treat/sports-injuries",
  },
];

export default data;
