import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customerCareNumber } from "../../utils";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faWhatsapp,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCopyright,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div>
      <div className="footer-main-container">
        <div className="footer-main-container1">
          <div className="footer-sub-container">
            <div className="footer-max-container">
              <h3>
                <a href="/">Physi Fit</a>
              </h3>
              {/* <p className="footer-sub-head">
                Learn Today to be your strongest self with Physiotherapy.
              </p> */}
              <div className="footer-left-container">
                <a href="/about" className="footer-list">
                  About Us
                </a>
                <a className="footer-list" href="/blog">
                  Blog Post
                </a>
                <a href="/terms" className="footer-list">
                  Terms and Conditions
                </a>
                <a className="footer-list" href="/terms#privacy">
                  Privacy Policy
                </a>
                <a className="footer-list" href="/faqs">
                  FAQs
                </a>
              </div>
            </div>
            <div className="footer-right-container">
              <h6 style={{ marginBottom: "10px" }}>
                <a href="/contact">Contact Us</a>
              </h6>
              <a
                className="footer-right-list"
                href={`tel:+91${customerCareNumber}`}
              >
                <FontAwesomeIcon
                  size="1x"
                  icon={faPhoneAlt}
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                {customerCareNumber}
              </a>
              <a
                className="footer-right-list"
                href="mailto:support@physifit.org"
              >
                <FontAwesomeIcon
                  size="1x"
                  icon={faEnvelope}
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                support@physifit.org
              </a>
              <h6
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                Follow us on
              </h6>
              <div className="icons footer-right-list">
                <div className="footers-icons">
                  <a href="https://www.facebook.com/org.physifit">
                    <FontAwesomeIcon size="2x" icon={faFacebookF} />
                  </a>
                  <a href="https://twitter.com/physifitorg">
                    <FontAwesomeIcon size="2x" icon={faTwitter} />
                  </a>
                  <a href={`http://wa.me/${customerCareNumber}`}>
                    <FontAwesomeIcon size="2x" icon={faWhatsapp} />
                  </a>
                  <a href="https://www.instagram.com/physifitorg/">
                    <FontAwesomeIcon size="2x" icon={faInstagram} />
                  </a>
                  <a
                    style={{ paddingRight: "6px", paddingLeft: "6px" }}
                    href="https://www.linkedin.com/company/physifit/about/"
                  >
                    <FontAwesomeIcon size="2x" icon={faLinkedin} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p
            style={{
              fontSize: "15px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <FontAwesomeIcon
              size="1x"
              icon={faCopyright}
              style={{ margin: "0px 10px 0px 0px" }}
            />
            2022 all rights reserverd
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
