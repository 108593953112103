import { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import "./Dropdown.css";
import DropdownContent from "./DropdownContent";

const Dropdown = ({ selected, setSelected, setDesError }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div>
      <div className="dropdown" onBlur={(e) => setIsActive(false)}>
        <div
          className="dropdown-button"
          onClick={(e) => setIsActive(!isActive)}
        >
          {selected === "" ? "Select" : selected}
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
        {isActive && (
          <div className="dropdown-content">
            {DropdownContent.map((option) => {
              return (
                <div
                  className="dropdown-items"
                  key={option.id}
                  onClick={(e) => {
                    setSelected(e.target.textContent);
                    setIsActive(false);
                    setDesError("");
                  }}
                >
                  <div>{option.name}</div>
                  {selected === option.name && (
                    <FontAwesomeIcon icon={faCheck} color="#45b6f7" />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  selected: PropTypes.string,
  setSelected: PropTypes.any,
  setDesError: PropTypes.any,
};
export default Dropdown;
