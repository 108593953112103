import logo from "../components/common/images/physifit25.png";
import Tick from "../components/common/images/TICK.png";
import Countdown from "react-countdown";
import "./ThankYouPage.css";
import PropTypes from "prop-types";

const ThankYouPage = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const email = params.email;

  setTimeout(function () {
    window.location.replace("/");
  }, 5000);

  const renderer = ({ seconds }) => {
    // Render a countdown
    return <span>{seconds}</span>;
  };
  return (
    <div>
      <div className="thank-main-container">
        <div className="thank-container">
          <div className="phys-logo">
            <img src={logo} className="logo-img" alt="" />
            <span className="logo-txt">Physi Fit</span>
          </div>
          <div className="thank-title">
            <h1>THANK YOU!</h1>
          </div>
          <div className="tick-img">
            <img src={Tick} alt="" />
          </div>
          <div className="thank-content">
            <p>Booking has been Confirmed.</p>
            <p>
              We've sent the details to <i>{email}</i>
            </p>
          </div>
          <h5 className="redirecting">
            Redirecting in{" "}
            <Countdown date={Date.now() + 5000} renderer={renderer} /> seconds
          </h5>
        </div>
      </div>
    </div>
  );
};

ThankYouPage.propTypes = {
  location: PropTypes.object,
};
export default ThankYouPage;
