import { FaArrowLeft } from "react-icons/fa";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  MdCheckCircle,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
} from "react-icons/md";

const TransactionDetails = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const propsData = location.state?.data;
  const history = useHistory();
  return (
    <div>
      <div className="transaction-details-container">
        <div className="tdetails-header">
          <div
            className="arrow-container"
            onClick={() => {
              history.goBack();
            }}
          >
            <FaArrowLeft size="1.5rem" color="#14a6c3" />
          </div>
          <div>Money Received</div>
        </div>
        <div className="tdetails">
          <div className="tdetails-info">
            <div className="tdetails-info-name">To {propsData.doctorName}</div>
            <div className="tdetails-info-amount">
              <span>Rs. {propsData.payableAmount}</span>{" "}
              <MdCheckCircle color="#69f569" size="1.5rem" />
            </div>
            <div className="tdetails-info-date">{propsData.toDate}</div>
          </div>
          <div className="tdetails-value" onClick={() => setOpen(!open)}>
            <div className="tdetails-value-heading">
              <div>Transaction Details</div>
              <div>
                {open ? (
                  <MdKeyboardArrowUp size="1.5rem" />
                ) : (
                  <MdKeyboardArrowDown size="1.5rem" />
                )}{" "}
              </div>
            </div>
            {open && (
              <div className="tdetails-value-content">
                <div className="tdetails-value-content-id">
                  <div className="transaction-id-name">Payment DateTime</div>
                  <div className="transaction-id-value">
                    {propsData.paymentDateTime}
                  </div>
                </div>
                <div>
                  <div className="transaction-id-name">
                    Unique Transaction Reference
                  </div>
                  <div className="transaction-id-value">{propsData.utr}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
