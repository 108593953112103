import { bucket } from "./../../utils";
const doctorData = [
  {
    id: 1,
    url:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/doctors/Dr.Susan.jpg",
    name: "Dr. Susan D'Souza",
    bio: ["M. P. T. in Orthopedic and Sports(Gold Medalist)"],
  },
  {
    id: 2,
    url:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/doctors/Dr.Shariff.jpg",
    name: "Dr. Mohammad Sharif",
    bio: ["M. P. T. in MSK"],
  },
  {
    id: 3,
    url:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/doctors/Dr.Pawan.jpg",
    name: "Dr. Pawan",
    bio: ["M. P. T. in MSK"],
  },
  {
    id: 5,
    url:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/doctors/Dr.Aafiya.jpg",
    name: "Dr. Aafiya Nahvi",
    bio: ["M. P. T. in Neurology and Paediatrics"],
  },
];

export default doctorData;
