import "./ChooseUs.css";
import { PropTypes } from "prop-types";
import images from "../../images/index";
const chooseData = [
  {
    id: 1,
    src: images.choosePick,
    text: "Book Instantly",
  },
  {
    id: 2,
    src: images.chooseBooking,
    text: "Pick a time",
  },
  {
    id: 3,
    src: images.chooseSecure,
    text: "100% Safe",
  },
];

function ChooseUsCard(props) {
  return (
    <div className="choose-us-content">
      <img src={props.src} alt={props.text} className="m-3" />
      <h5 className="font-change fw-normal">{props.text}</h5>
    </div>
  );
}

function ChooseUs() {
  return (
    <div className="choose-us">
      {chooseData.map((props) => (
        <ChooseUsCard key={props.id} src={props.src} text={props.text} />
      ))}
    </div>
  );
}
ChooseUsCard.propTypes = {
  src: PropTypes.string,
  text: PropTypes.string,
};

ChooseUs.propTypes = {
  id: PropTypes.string,
  src: PropTypes.string,
  text: PropTypes.string,
};

export default ChooseUs;
