import {
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  InputAdornment,
  Button,
  Table,
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Slide,
  Alert,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import axiosInstance from "../CustomAxios";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CouponUpdate from "./CouponUpdate";

const DashboardCoupons = () => {
  const [value, setValue] = useState("");
  const [coupon, setCoupon] = useState("");
  const [searchedData, setSearchData] = useState("");
  const [updateCoupon, setUpdateCoupon] = useState("");
  const [discount, setDiscount] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [deleteCoupon, setDeleteCoupon] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const handleChange = async (value) => {
    setValue(value);
    setEdit(false);
    if (value === "view") {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `/api/dashboard/coupons?limit=${limit}&offset=${page}`
        );
        setCoupons(res.data);
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  };
  const handlePageChange = async (temp) => {
    setPage(temp);
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/dashboard/coupons?limit=${limit}&offset=${temp}`
      );
      setCoupons(res.data);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setLoading(false);
    }, 700);
  };
  const handleLimitChange = async (e) => {
    setPage(0);
    setLimit(e.target.value);
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/dashboard/coupons?limit=${e.target.value}&offset=0`
      );
      setCoupons(res.data);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setLoading(false);
    }, 700);
  };
  const createCoupon = async () => {
    if (coupon === "") {
      alert("Enter Coupon Code");
      return;
    } else if (discount === "") {
      alert("Enter Discount Percentage");
      return;
    } else if (discount <= 0 || discount > 100) {
      alert("Enter Valid Discount Percentage");
      return;
    } else if (start === "") {
      alert("Enter Coupon's Start Date");
      return;
    } else if (end === "") {
      alert("Enter Coupon's End Date");
      return;
    } else if (start > end) {
      alert("Start date cannot be after end date!");
      return;
    }
    setLoading(true);
    const data = {
      coupon_code: coupon.toUpperCase(),
      offer_name: coupon.toUpperCase(),
      discount_percentage: discount,
      start_date: start,
      start_time: "00:00:00",
      end_date: end,
      end_time: "23:59:59",
    };
    try {
      await axiosInstance.post("/api/dashboard/coupons", data);
      alert("Coupon Added Successfully");
    } catch (error) {
      alert("Coupon Creation Failed");
      console.log(error);
    }
    handleReset();
    setLoading(false);
  };
  const handleReset = () => {
    setCoupon("");
    setDiscount("");
    setStart("");
    setEnd("");
  };
  const handleDialog = (event, flag, data) => {
    setDeleteCoupon(data);
    setIsDelete(flag);
    setOpen(true);
  };
  const searchCoupon = async () => {
    if (coupon === "") {
      alert("Enter Coupon Code");
      return;
    }
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/dashboard/coupons?coupon_code=${coupon.toUpperCase()}`
      );
      setSearchData(res.data.data);
    } catch (error) {
      alert("Coupon doesn't exists...");
      console.log(error);
    }
    setCoupon("");
    setLoading(false);
  };
  const handleDelete = async () => {
    setDeleteLoad(true);
    try {
      const res = await axiosInstance({
        method: "DELETE",
        url: `/api/dashboard/coupons?delete=${isDelete}`,
        data: {
          coupon_code: deleteCoupon.coupon_code,
        },
      });
      if (res.status === 200 && isDelete) {
        alert("Coupon Deleted Successfully");
      } else if (res.status === 200 && !isDelete) {
        alert("Coupon Expired Successfully");
      }
    } catch (error) {
      if (isDelete) {
        alert("Couldn't delete the coupon");
      } else {
        alert("Couldn't expire the coupon");
      }
      console.log(error);
    }
    setOpen(false);
    handleChange("View");
    setSearchData("");
    setDeleteLoad(false);
  };
  const handleEdit = (e, coupon) => {
    setUpdateCoupon(coupon);
    setEdit(true);
  };
  return (
    <Stack spacing={2} paddingBottom="2rem">
      <Typography variant="h5" textAlign="center" style={{ marginTop: "2rem" }}>
        Manange Coupons
      </Typography>
      <Stack
        alignItems={{ xs: "flex-start", sm: "flex-start", md: "center" }}
        width={{ xs: "80%", sm: "80%", md: "100%" }}
        paddingLeft={{ xs: "5rem", sm: "5rem", md: "0" }}
      >
        <FormControl>
          <RadioGroup
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            row
          >
            <FormControlLabel
              sx={{ paddingRight: { xs: "10px", sm: "10px", md: "30px" } }}
              control={<Radio />}
              label="Create Coupon"
              value="create"
              style={{
                color: value === "create" ? "#1983D8" : "rgba(0,0,0,0.6)",
              }}
            />
            <FormControlLabel
              sx={{ paddingRight: { xs: "10px", sm: "10px", md: "30px" } }}
              control={<Radio />}
              label="Search Coupon"
              value="search"
              style={{
                color: value === "search" ? "#1983D8" : "rgba(0,0,0,0.6)",
              }}
            />
            <FormControlLabel
              control={<Radio />}
              label="View Coupons"
              value="view"
              style={{
                color: value === "view" ? "#1983D8" : "rgba(0,0,0,0.6)",
              }}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      <Stack>
        {value === "create" ? (
          <Stack spacing={2} width="80%" margin="0 auto">
            <TextField
              variant="outlined"
              style={{ backgroundColor: "azure" }}
              label="Enter Coupon Code"
              type="text"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
              focused
            ></TextField>
            <TextField
              variant="outlined"
              style={{ backgroundColor: "azure" }}
              label="Enter Discount Percentage"
              type="number"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              focused
            ></TextField>
            <TextField
              variant="outlined"
              style={{ backgroundColor: "azure" }}
              label="Enter Start Date"
              type="date"
              value={start}
              onChange={(e) => setStart(e.target.value)}
              focused
            ></TextField>
            <TextField
              variant="outlined"
              style={{ backgroundColor: "azure" }}
              label="Enter End Date"
              type="date"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
              focused
            ></TextField>
            <Stack
              paddingTop="1rem"
              direction="row"
              justifyContent="space-evenly"
            >
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={createCoupon}
              >
                Create
              </LoadingButton>
              <Button variant="contained" onClick={handleReset}>
                Reset
              </Button>
            </Stack>
          </Stack>
        ) : value === "search" ? (
          <Stack>
            {!edit && (
              <Stack>
                <TextField
                  variant="outlined"
                  style={{
                    backgroundColor: "azure",
                    width: "80%",
                    margin: "0 auto",
                  }}
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                  label="Enter Coupon Code"
                />
                <Stack direction="row" justifyContent="space-evenly">
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    style={{
                      width: "fit-content",
                      textTransform: "none",
                      margin: "2rem auto 0",
                    }}
                    onClick={searchCoupon}
                  >
                    Search
                  </LoadingButton>
                  <Button
                    variant="contained"
                    style={{
                      width: "fit-content",
                      textTransform: "none",
                      margin: "2rem auto 0",
                    }}
                    onClick={() => setSearchData("")}
                  >
                    Cancel
                  </Button>
                </Stack>
                {searchedData !== "" && (
                  <Stack
                    width="100%"
                    marginTop="1rem"
                    position={{
                      xs: "absolute",
                      sm: "absolute",
                      md: "relative",
                    }}
                    padding={{
                      xs: "9rem 5% 2rem",
                      sm: "9rem 5% 2rem",
                      md: "1rem",
                    }}
                  >
                    <Paper sx={{ maxWidth: "100%", overflow: "hidden" }}>
                      <TableContainer>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ fontSize: "16px" }}>
                                Coupon Name
                              </TableCell>
                              <TableCell style={{ fontSize: "16px" }}>
                                Discount %
                              </TableCell>
                              <TableCell style={{ fontSize: "16px" }}>
                                Start Date
                              </TableCell>
                              <TableCell style={{ fontSize: "16px" }}>
                                End Date
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "16px" }}
                                align="center"
                              >
                                Status
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "16px" }}
                                align="center"
                              >
                                Update
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "16px" }}
                                align="center"
                              >
                                Expire
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "16px" }}
                                align="center"
                              >
                                Delete
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>{searchedData.coupon_code}</TableCell>
                              <TableCell>
                                {searchedData.discount_percentage}
                              </TableCell>
                              <TableCell>{searchedData.start_date}</TableCell>
                              <TableCell>{searchedData.end_date}</TableCell>
                              {searchedData.active ? (
                                <TableCell align="center">
                                  <FiberManualRecordIcon color="success"></FiberManualRecordIcon>
                                </TableCell>
                              ) : (
                                <TableCell align="center">
                                  <FiberManualRecordIcon color="error"></FiberManualRecordIcon>
                                </TableCell>
                              )}
                              <TableCell align="center">
                                <IconButton
                                  style={{
                                    width: "fit-content",
                                    textTransform: "none",
                                  }}
                                  onClick={() => setEdit(true)}
                                  variant="contained"
                                >
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  style={{
                                    width: "fit-content",
                                    textTransform: "none",
                                  }}
                                  variant="contained"
                                  onClick={(e) =>
                                    handleDialog(e, false, searchedData)
                                  }
                                >
                                  <HighlightOffIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  style={{
                                    width: "fit-content",
                                    textTransform: "none",
                                  }}
                                  variant="contained"
                                  onClick={(e) =>
                                    handleDialog(e, true, searchedData)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Stack>
                )}
              </Stack>
            )}
            {edit && (
              <CouponUpdate
                couponCode={searchedData.coupon_code}
                discount={searchedData.discount_percentage}
                start={searchedData.start_date}
                end={searchedData.end_date}
                setEdit={setEdit}
                handleChange={handleChange}
              ></CouponUpdate>
            )}
          </Stack>
        ) : value === "view" ? (
          <Stack backgroundColor="#f6f6f6">
            {loading && (
              <Slide direction="down" in={loading}>
                <Stack>
                  <RotateLeftIcon
                    sx={{
                      fontSize: "3rem",
                      margin: "0 auto",
                      color: "rgba(0,0,0,0.6)",
                      animation: "spin 2s linear infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(360deg)",
                        },
                        "100%": {
                          transform: "rotate(0deg)",
                        },
                      },
                    }}
                  />
                </Stack>
              </Slide>
            )}
            {!loading && !edit && (
              <Stack>
                {coupons === null || coupons?.length === 0 ? (
                  <Alert variant="outlined" severity="error">
                    No Coupons
                  </Alert>
                ) : (
                  <Stack
                    width="100%"
                    position={{
                      xs: "absolute",
                      sm: "absolute",
                      md: "relative",
                    }}
                    padding="0 5%"
                    spacing={2}
                  >
                    <Paper sx={{ maxWidth: "100%", overflow: "hidden" }}>
                      <TableContainer sx={{ maxHeight: 360 }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Sl. No.</TableCell>
                              <TableCell>Coupon Name</TableCell>
                              <TableCell>Discount %</TableCell>
                              <TableCell>Start Date</TableCell>
                              <TableCell>End Date</TableCell>
                              <TableCell align="center">Status</TableCell>
                              <TableCell align="center">Update</TableCell>
                              <TableCell align="center">Expire</TableCell>
                              <TableCell align="center">Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {coupons?.data?.map((coupon, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {page * limit + index + 1}
                                </TableCell>
                                <TableCell>{coupon.coupon_code}</TableCell>
                                <TableCell>
                                  {coupon.discount_percentage}
                                </TableCell>
                                <TableCell>{coupon.start_date}</TableCell>
                                <TableCell>{coupon.end_date}</TableCell>
                                {coupon.active ? (
                                  <TableCell align="center">
                                    <FiberManualRecordIcon color="success"></FiberManualRecordIcon>
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    <FiberManualRecordIcon color="error"></FiberManualRecordIcon>
                                  </TableCell>
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    style={{
                                      width: "fit-content",
                                      textTransform: "none",
                                    }}
                                    onClick={(e) => handleEdit(e, coupon)}
                                    variant="contained"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    style={{
                                      width: "fit-content",
                                      textTransform: "none",
                                    }}
                                    variant="contained"
                                    onClick={(e) =>
                                      handleDialog(e, false, coupon)
                                    }
                                  >
                                    <HighlightOffIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    style={{
                                      width: "fit-content",
                                      textTransform: "none",
                                    }}
                                    variant="contained"
                                    onClick={(e) =>
                                      handleDialog(e, true, coupon)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                    <Stack
                      direction="row"
                      padding={{ xs: "0", sm: "0", md: "1rem" }}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <LoadingButton
                        variant="contained"
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 0}
                      >
                        <ArrowBackIosIcon />
                      </LoadingButton>
                      <Typography>Page: {page + 1}</Typography>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <InputLabel>Rows per Page</InputLabel>
                        <Select
                          value={limit}
                          onChange={handleLimitChange}
                          label="Rows per Page"
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={15}>15</MenuItem>
                        </Select>
                      </FormControl>
                      <LoadingButton
                        variant="contained"
                        onClick={() => handlePageChange(page + 1)}
                        disabled={coupons.total <= page * limit + coupons.count}
                      >
                        <ArrowForwardIosIcon />
                      </LoadingButton>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}
            {!loading && edit && (
              <CouponUpdate
                couponCode={updateCoupon.coupon_code}
                discount={updateCoupon.discount_percentage}
                start={updateCoupon.start_date}
                end={updateCoupon.end_date}
                setEdit={setEdit}
                handleChange={handleChange}
              ></CouponUpdate>
            )}
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
      <Stack>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            Are you sure about {isDelete ? "deleting" : "expiring"}{" "}
            {deleteCoupon.coupon_code} Coupon?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you want to {isDelete ? "delete" : "expire"} this coupon then
              press "YES" else "NO"
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={deleteLoad}
              onClick={handleDelete}
              variant="text"
              style={{ width: "fit-content" }}
            >
              Yes
            </LoadingButton>
            <Button
              onClick={() => setOpen(false)}
              variant="text"
              style={{ width: "fit-content" }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default DashboardCoupons;
