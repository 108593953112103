import { faqData } from "./Info";
import FaqSection from "./FaqSection";
import NavBar from "./../nav-bar/NavBar";
import Footer from "../footer/Footer";
import MobileFooter from "../footer/MobileFooter";
function FaqCollapse() {
  return (
    <>
      <NavBar />
      <section className="header underline">
        <p className="header-content font-change">Frequently Asked Questions</p>
      </section>
      <div style={{ marginBottom: "4rem" }} className="accordion">
        {faqData.map((data) => {
          return (
            <FaqSection
              key={data.id}
              question={data.question}
              answer={data.answer}
            />
          );
        })}
      </div>
      <div className="desktop-footer">
        <Footer />
      </div>
      <div className="mobile-footer">
        <MobileFooter />
      </div>
    </>
  );
}

export default FaqCollapse;
