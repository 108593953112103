import "./Payout.css";
import { FaArrowLeft } from "react-icons/fa";
import { MdAccountBalanceWallet } from "react-icons/md";
import PayoutCard from "./PayoutCard";
import axiosInstance from "../CustomAxios";

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const Payout = () => {
  const location = useLocation();
  const [payoutHistory, setPayoutHistory] = useState([]);
  const propsData = location.state?.data;
  const history = useHistory();
  const fetchTransactionDetails = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/api/advices/${propsData.id}?utr=any`
      );
      if (data) {
        setPayoutHistory(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchTransactionDetails();
  }, []);

  return (
    <div>
      <div className="payout-container">
        <div className="payout-amount">
          <div className="">Payout</div>
          <div className="">
            <MdAccountBalanceWallet size="2.5rem" />
          </div>
          <div className="">{propsData.amount}</div>
          {/* {user === "admin" && <button className="pay-btn">Pay</button>} */}

          <div
            className="arrow-container top-left"
            onClick={() => {
              history.goBack();
            }}
          >
            <FaArrowLeft size="1.5rem" color="#14a6c3" />
          </div>
        </div>
        <div className="payout-history">
          <h4>Payout History</h4>
          <div className="payout-history-container">
            {payoutHistory.map((curr, i) => {
              return (
                <PayoutCard
                  payoutData={curr}
                  doctorName={propsData.name}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payout;
