import { useState } from "react";
import PropTypes from "prop-types";
import "./FaqCollapse.css";
import { IoIosArrowDropup } from "react-icons/io";
export default function FaqSection({ question, answer }) {
  const [visible, toggleVisibility] = useState(false);

  return (
    <>
      <div className="accordion-item">
        <div
          onClick={() => toggleVisibility(!visible)}
          className={
            !visible ? "accordion-item-header" : "accordion-item-header-blue"
          }
        >
          <p>{question}</p>
          <button className={!visible ? "faqButton" : "faqButton-active"}>
            <IoIosArrowDropup />
          </button>
        </div>
        {visible && (
          <div
            className={`accordion-item-body ${
              visible ? "accordion-item-body-active" : ""
            }`}
          >
            <div className="accordion-item-body-content">
              <ul>
                {answer.map((ans, i) => (
                  <li key={i}>{ans}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

FaqSection.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.array,
};
