import "../plan/Plan.css";
import "./MainContent.css";
import "../nav-bar/NavBar.css";
import images from "../../images";
import ServiceCard from "../../components/service-card/ServiceCard";
import ChooseUs from "../choose-us/ChooseUs";
import MobileFooter from "../footer/MobileFooter";
import InfoCarousel from "../../components/info-carousel/InfoCarousel";
import ConditionCarousel from "../../components/condition-carousel/ConditionCarousel";
import PatientTestimonials from "../../components/patient-testimonials/PatientTestimonials";
import Footer from "../footer/Footer";
import HomeContent from "../home-content/HomeContent";
import OurProcedure from "../../components/OurProcedure";

function MainContent() {
  return (
    <>
      <div className="main-content-2">
        <HomeContent />
        <section className="procedure">
          <section className="header underline">
            <p className="header-content font-change">Our Procedure</p>
          </section>
          {/* <div className="procedure-cards d-flex flex-lg-nowrap flex-wrap flex-sm-row flex-column justify-content-around align-items-center"> */}
          <div className="procedure-cards">
            <ServiceCard src={images.process1} alt="Consultation" />
            <img src={images.arrow} alt="arrow" className="arrow" />
            <ServiceCard
              src={images.process3}
              alt="Planning of Future Sessions"
            />
            <img src={images.arrow} alt="arrow" className="arrow" />
            <ServiceCard src={images.process2} alt="Treatment Course" />
            <img src={images.arrow} alt="arrow" className="arrow" />
            <ServiceCard src={images.process4} alt="Follow up and Review" />
          </div>
          <div className="procedure-cards-mob">
            <OurProcedure />
          </div>
        </section>

        <section>
          <section className="header underline">
            <p className="header-content header-less-margin">
              Our Expert Physiotherapists
            </p>
            <p className="header-body">
              We ensure proper assignment of physical therapist according to our
              <br /> clients' needs to ensure fast and complete recovery
            </p>
          </section>
          <InfoCarousel />
        </section>

        <section>
          <section className="header underline">
            <p className="header-content">Conditions We Treat</p>
            <p className="header-body">
              Physi Fit can help you deal with a variety of conditions
            </p>
          </section>
          <ConditionCarousel />
        </section>

        <section>
          <section id="testimonials" className="header underline">
            <p className="header-content font-change header-less-margin">
              Patient Testimonials
            </p>
          </section>
          <PatientTestimonials />
        </section>

        <section>
          <section className="header underline pb-2">
            <p className="header-content">Why Choose Us</p>
          </section>
          <ChooseUs />
        </section>
      </div>
      <div className="desktop-footer">
        <Footer />
      </div>
      <div className="mobile-footer">
        <MobileFooter />
      </div>
    </>
  );
}

export default MainContent;
