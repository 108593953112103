import React, { useRef } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import MobileFooter from "../main-ui/footer/MobileFooter";
import NavBar from "../main-ui/nav-bar/NavBar";
import Footer from "../main-ui/footer/Footer";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContactUs.css";
import { customerCareNumber } from "../utils";

function ContactUs() {
  const history = useHistory();

  const [successModal, setsuccessModal] = React.useState(false);
  const [failureModal, setfailureModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const username = useRef();
  const email = useRef();
  const message = useRef();

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function redirect() {
    history.push("/");
  }

  const validate = () => {
    const username = document.contactus.username.value;
    if (username === "") {
      alert("Name Should not be empty");
      return false;
    }
    const valid = /^[a-zA-Z ]+$/.test(username);
    if (!valid) {
      alert("The Name should contain only characters");
      return false;
    }

    const email = document.contactus.email.value;
    if (email === "") {
      alert("Email is mandatory");
      return false;
    }

    if (!validateEmail(email)) {
      alert("Enter a Valid Email Address");
      return false;
    }

    const message = document.contactus.message.value;
    if (message === "") {
      alert("Comments can't be Empty");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (validate()) {
      e.preventDefault();
      const data = {
        name: username.current.value,
        email: email.current.value,
        message: message.current.value,
      };
      try {
        const res = await axios.post("/api/contact", data);
        if (res.status === 201) {
          setsuccessModal(true);
          setTimeout(redirect, 3000);
        } else {
          setfailureModal(true);
        }
      } catch (error) {
        alert(error.message);
        setfailureModal(true);
      }
    }
    setIsLoading(false);
  };
  return (
    <>
      <NavBar />
      <SuccessModal show={successModal} onHide={() => setsuccessModal(false)} />

      <FailureModal show={failureModal} onHide={() => setfailureModal(false)} />
      <div className="contact-us">
        <section className="contact-us-hero-section">
          <div className="contact-us-main-content">
            <p className="contact-us-header header-color">CONTACT US</p>
            <p className="text-color">Feel free to get in touch with us.</p>
          </div>
          <div className="contact-us-contacts">
            <div className="contact-us-phone">
              <p className="contact-us-contacts-header header-color">EMAIL</p>
              <p className="contact-us-contacts-lede text-color">
                support@physifit.org
              </p>
            </div>
            <div className="contact-us-underline"></div>
            <div className="contact-us-email">
              <p className="contact-us-contacts-header header-color">PHONE</p>
              <p className="contact-us-contacts-lede text-color">
                +91-{customerCareNumber}
              </p>
            </div>
          </div>
        </section>

        <section className="contact-us-body">
          <div className="contact-us-body-header">Send Us a Message</div>
          <form action="" name="contactus">
            <div className="contact-us-form-element">
              <label htmlFor="username" className="required-field">
                Name
              </label>
              <input
                ref={username}
                type="text"
                name="username"
                id="username"
                required
              />
            </div>
            <div className="contact-us-form-element">
              <label htmlFor="email" className="required-field">
                Email
              </label>
              <input
                ref={email}
                type="email"
                name="email"
                id="email"
                required
              />
            </div>
            <div className="contact-us-form-element">
              <label htmlFor="message" className="required-field">
                Message
              </label>
              <textarea
                ref={message}
                name="message"
                id="message"
                rows="5"
                required
              ></textarea>
            </div>
            <div className="contact-us-form-element">
              <div className="contact-us-btn-parent">
                {!isLoading && (
                  <div className="contact-us-btn-submit">
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="btn-booking"
                    >
                      <a
                        href="/booking"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        Submit
                      </a>
                    </button>
                  </div>
                )}
                {isLoading && (
                  <div className="contact-us-btn-submit">
                    <button className="btn-booking" disabled>
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="icon-spinner"
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </section>
      </div>
      <div className="desktop-footer">
        <Footer />
      </div>
      <div className="mobile-footer">
        <MobileFooter />
      </div>
    </>
  );
}

function SuccessModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h3 style={{ fontSize: "1.1rem" }} className="text-center">
          Thank You for reaching us Out
        </h3>
        <hr />
        <h4 style={{ fontSize: "0.9rem" }} className="text-center">
          Your Response have been received Successfully
        </h4>
      </Modal.Body>
    </Modal>
  );
}

function FailureModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h3 style={{ fontSize: "1.1rem" }} className="text-center">
          Your response was not submitted
        </h3>
        <hr />
        <h4 style={{ fontSize: "0.9rem" }} className="text-center">
          Something went Wrong
        </h4>
      </Modal.Body>
    </Modal>
  );
}

export default ContactUs;
