import "./Payout.css";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdCheckCircle } from "react-icons/md";
import PropTypes from "prop-types";
const PayoutCard = ({ payoutData, doctorName }) => {
  // This function is for Formatting the date in given format
  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dateObj = new Date(inputDate);
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  }
  const {
    payable_amount: payableAmount,
    to_date_time: toDateTime,
    payment_date_time: paymentDateTime,
    utr,
    razorpay_id: razorpayId,
  } = payoutData;
  const formattedPaymentDateTime = formatDate(paymentDateTime);
  const formattedEndDate = formatDate(toDateTime);

  return (
    <div>
      <Link
        className="none"
        to={{
          pathname: `/doctor/payout/payment`,
          state: {
            data: {
              doctorName,
              utr,
              razorpayId,
              payableAmount,
              paymentDateTime: formattedPaymentDateTime,
              toDate: formattedEndDate,
            },
          },
        }}
      >
        <div className="payout-history-card">
          <div className="paid">
            <span>
              <MdCheckCircle color="#69f569" size="1.2rem" />
            </span>{" "}
            Paid
          </div>
          <div className="history-amount">Rs. {payableAmount}</div>
          <div className="history-date">
            <div className="history-date-left">{formattedEndDate}</div>
            <div>
              <FaChevronRight color="black" />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
PayoutCard.propTypes = {
  payoutData: PropTypes.object,
  doctorName: PropTypes.string,
};

export default PayoutCard;
