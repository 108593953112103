export const faqData = [
  {
    id: 1,
    question: "What caused my pain/injury?",
    answer: [
      "Direct trauma or fall or hit by an object",
      "Wrong movements and techniques used during daily activities or sports",
      "Poor posture and deformities",
      "Degenerative, inflammatory or infective conditions affecting the muscles and skeletal system",
      "Burns",
      "Overuse and repeated activities with inadequate rest breaks",
    ],
  },
  {
    id: 2,
    question: "Why do I need online physiotherapy?",
    answer: [
      "Convenience of treatment from your home",
      "One click away from a physiotherapy consultation and treatment",
      "We train caregivers to perform activities with ease",
      "Decreases the chances of cross infection",
      "We charge less",
    ],
  },
  {
    id: 3,
    question: "What should I avoid?",
    answer: [
      "Complete bed rest unless advised so",
      "Excessive alcohol drinking and smoking",
      "Avoid taking over the counter medications for prolonged period",
      "Working through fatigue",
      "Avoid activities that aggravate the pain",
    ],
  },
  {
    id: 4,
    question: "What can I do to assist my recovery?",
    answer: [
      "Maintain a physically active lifestyle unless advised against",
      "Use of hot packs or cold packs according to the duration of your injury",
      "Maintain adequate nutrition and hydration",
      "Take appropriate rest time",
      "Consult a Physiotherapist or Orthopaedic surgeon to understand more about your condition",
    ],
  },
  {
    id: 5,
    question: "Do I have to stop what I am doing now: training/gym sessions?",
    answer: [
      "Avoid activities that cause and aggravate your symptoms",
      "Depending upon your condition you may be allowed gym sessions with restriction towards certain activities",
      "Ask your doctor and therapist for the list of activities to be avoided",
      "Check with your instructor or trainer if you are performing the said exercise correctly without and substitution movements from other body parts",
    ],
  },
  {
    id: 6,
    question: "What is the time duration of one session?",
    answer: [
      "Each session may vary from a minimum of 30 minutes to a maximum of 1 hour duration",
    ],
  },
  {
    id: 7,
    question: "What preparation is required from your end before a session?",
    answer: [
      "Send your doctor reports or prescription if any on whatsapp",
      "Ensure appropriate internet connection and a quiet ambience",
      "Place the phone on a stand or stool",
      "Sit on a bed",
      "Keep a pillow, towel and water bottle in near reach distance",
      "Keep a chair handy in the surrounding",
    ],
  },
  {
    id: 8,
    question: "What can I expect on my first session?",
    answer: [
      "A thorough understanding of your condition",
      "Do’s, don’t’s and precautions to be taken to avoid further injury",
      "An exercise session",
      "Explanation to caregivers for adherence to home- based exercise program",
      "Physiotherapist prescription sheet",
    ],
  },
  {
    id: 9,
    question: "How will physiotherapy help?",
    answer: [
      "It will aid in faster recovery",
      "Decrease your symptoms (pain, stiffness. swelling)",
      "Help in avoiding surgery",
      "Improve joint flexibility, range of movement and strength",
      "Provide you with coping strategies",
      "Perform an analysis of your activities and provide biomechanicaladjustments to improve them",
      "Improve your overall function and quality of life",
    ],
  },
  {
    id: 10,
    question: "How long will it take to get better?",
    answer: [
      "It depends on the root cause of the problem, treatment strategy undertaken and, the structures involved in the injury eg., bone, joint, muscle, nerve, ligaments, meniscus and cartilage",
    ],
  },
];
