import { Stack, Typography, ThemeProvider, createTheme } from "@mui/material";
import { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import DashboardPatients from "./DashboardPatients";
import DashboardCoupons from "./DashboardCoupons";
import TopNav from "./TopNav";
import images from "../images";

const DashboardLanding = () => {
  const location = useLocation();
  const history = useHistory();
  const [active, setActive] = useState(location.search.substring(1));
  const handleActive = (value) => {
    setActive(value);
    history.push(`/dashboard?${value}`);
  };
  const theme = createTheme({
    typography: {
      fontFamily: ["Axiforma", "sans-serif"].join(","),
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Stack direction="column" backgroundColor="#f6f6f6" spacing={4}>
        <TopNav />
        <Stack
          alignItems="center"
          minHeight="100vh"
          width="100%"
          direction="column"
          spacing={4}
        >
          <Typography variant="h5" textAlign="center">
            Dashboard
          </Typography>
          <Stack
            width="80%"
            justifyContent="space-between"
            alignItems="center"
            direction={{ xs: "column", sm: "column", md: "row" }}
            spacing={4}
          >
            <Stack
              height="135px"
              width="250px"
              justifyContent="end"
              alignItems="center"
              backgroundColor={active === "appointments" ? "#1983D8" : "white"}
              boxShadow="1px 2px 15px rgba(0,0,0,0.1)"
              border="none"
              borderRadius="8px"
              direction="column"
              style={{ cursor: "pointer" }}
              onClick={() => handleActive("appointments")}
            >
              <Typography
                variant="body1"
                fontWeight="400"
                color={active === "appointments" ? "white" : "black"}
              >
                Appointments
              </Typography>
              <img
                src={images.appointmentIcon}
                alt="appointments-icon"
                style={{ width: "135px", height: "60px" }}
              ></img>
            </Stack>
            <Stack
              height="135px"
              width="250px"
              justifyContent="end"
              alignItems="center"
              backgroundColor={active === "patients" ? "#1983D8" : "white"}
              boxShadow="1px 2px 15px rgba(0,0,0,0.1)"
              border="none"
              borderRadius="8px"
              direction="column"
              style={{ cursor: "pointer" }}
              onClick={() => handleActive("patients")}
            >
              <Typography
                variant="body1"
                fontWeight="400"
                color={active === "patients" ? "white" : "black"}
              >
                Patients
              </Typography>
              <img
                src={images.patientIcon}
                alt="patients-icon"
                style={{ width: "135px", height: "60px" }}
              ></img>
            </Stack>
            <Stack
              height="135px"
              width="250px"
              justifyContent="end"
              backgroundColor={active === "coupons" ? "#1983D8" : "white"}
              boxShadow="1px 2px 15px rgba(0,0,0,0.1)"
              border="none"
              borderRadius="8px"
              direction="column"
              style={{ cursor: "pointer" }}
              onClick={() => handleActive("coupons")}
            >
              <Typography
                variant="body1"
                fontWeight="400"
                color={active === "coupons" ? "white" : "black"}
                textAlign="center"
              >
                Coupons
              </Typography>
              <Stack direction="row">
                <img
                  src={images.couponLeft}
                  alt="coupons-icon"
                  style={{ height: "60px" }}
                ></img>
                <img
                  src={images.couponRight}
                  alt="coupons-icon"
                  style={{ height: "60px" }}
                ></img>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            {active === "appointments" ? (
              // <DashboardAppointments />
              <></>
            ) : active === "patients" ? (
              <DashboardPatients />
            ) : active === "coupons" ? (
              <DashboardCoupons />
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

export default DashboardLanding;
