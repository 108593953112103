import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./ConditionCarousel.css";
import conditionData from "./Info";
import ConditionCard from "../condition-card/ConditionCard";

export default function ConditionCarousel() {
  const settings = {
    className: "condition-carousel",
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 300,
        settings: {
          arrows: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
    ],
  };
  const divStyle = {
    boxSizing: "border-box",
    padding: "0px 50px",
  };
  return (
    <div style={divStyle}>
      <Slider {...settings}>
        {conditionData.map((props) => (
          <ConditionCard
            link={props.link}
            key={props.id}
            condition={props.condition}
            description={props.description}
          />
        ))}
      </Slider>
    </div>
  );
}

ConditionCarousel.propTypes = {
  id: PropTypes.any,
  condition: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};
