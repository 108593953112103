const initialState = {
  access_token: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACCESS_TOKEN":
      return { ...state, access_token: action.token };
    default:
      return state;
  }
};

export default reducer;
