import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import PropTypes from "prop-types";
import styled from "styled-components";
import "./TestimonialCard.css";

export default function TestimonialCard(props) {
  return (
    <div className="card testimonial-card mx-auto">
      <div className="card-top testimonial-card--top">
        <div className="card-header font-change">
          <QuoteLeft style={{ width: "18px", margin: 0 }} />
          {props.text}
          <QuoteRight style={{ width: "18px", margin: 0 }} />
        </div>
      </div>
      <div className="testimonial-card-img">
        <img src={props.img} alt="patient-image" />
      </div>
      <div className="testimonial-card-info text-center">
        <p className="testimonial-card-text font-change">{props.name}</p>
        <p className="testimonial-card-text-muted text-muted font-change">
          {props.designation}
        </p>
      </div>
    </div>
  );
}

TestimonialCard.propTypes = {
  text: PropTypes.string,
  designation: PropTypes.string,
  name: PropTypes.string,
  img: PropTypes.string,
};

const QuoteLeft = styled(FaQuoteLeft)`
  color: white;
  height: var(--colon-size);
  width: var(--colon-size);
  position: absolute;
  left: calc(var(--colon-size) * -1);
  top: calc(var(--colon-size) / 2 * -1);
`;

const QuoteRight = styled(FaQuoteRight)`
  -webkit-transform: rotate(180deg) scaleX(-1);
  transform: rotate(180deg) scaleX(-1);
  color: white;
  height: var(--colon-size);
  width: var(--colon-size);
  position: absolute;
  right: calc(var(--colon-size) * -1);
  bottom: calc(var(--colon-size) / 2 * -1);
`;
