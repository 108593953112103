import { NavLink, useHistory } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import "./NavBar.css";
import PhysifitLogo from "../../images/website-logo.png";
import { Button } from "@mui/material";

function NavBar() {
  const history = useHistory();
  const handleNav = () => {
    const doctor = JSON.parse(localStorage.getItem("Doctor"));
    if (doctor !== null) {
      history.push("/dashboard?patients");
    } else {
      const User = JSON.parse(localStorage.getItem("Profile"));
      if (User !== null) {
        history.push(`/user/dashboard/${User.data.data.id}`);
      } else {
        history.push(`/user/login`);
      }
    }
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid shadow">
          <a className="navbar-brand" href="/">
            <img src={PhysifitLogo} alt="Physifit-Logo" className="nav-logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item p-2">
                <NavLink
                  to="/"
                  exact
                  className="nav-link"
                  aria-current="page"
                  activeClassName="active"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item p-2">
                <NavLink
                  to="/about"
                  className="nav-link"
                  aria-current="page"
                  activeClassName="active"
                >
                  About Us
                </NavLink>
              </li>
              {/* <li className="nav-item  p-2">
                <NavLink
                  to="/comingsoon"
                  className="nav-link"
                  aria-current="page"
                  activeClassName="active"
                >
                  Our Services
                </NavLink>
              </li> */}
              <li className="nav-item p-2">
                <NavLink
                  to="/blog"
                  className="nav-link"
                  aria-current="page"
                  activeClassName="active"
                >
                  Blog
                </NavLink>
              </li>
              <li className="nav-item p-2">
                <NavLink
                  to="/contact"
                  className="nav-link"
                  aria-current="page"
                  activeClassName="active"
                >
                  Contact Us
                </NavLink>
              </li>
              <li className="nav-item p-2 nav-btn-desk">
                <div className="home-container-btn-parent">
                  <div className="home-content-btn">
                    <button
                      type="button"
                      className="btn-booking"
                      style={{ padding: "5px 9px" }}
                    >
                      <a
                        href="/booking"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        Booking
                      </a>
                    </button>
                  </div>
                </div>
              </li>
              <li className="nav-item p-2 nav-btn-mob">
                <div className="mob-btn-book">
                  <button
                    type="button"
                    className="mob-book"
                    style={{ padding: "5px 9px" }}
                  >
                    <a
                      href="/booking"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Booking
                    </a>
                  </button>
                </div>
              </li>
              <li className="nav-item p-2">
                <Button
                  style={{ fontSize: "36px", paddingTop: "0" }}
                  onClick={handleNav}
                  className="nav-link"
                >
                  <FaUserCircle></FaUserCircle>
                </Button>
              </li>
              <div className=""></div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
