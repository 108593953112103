const DropdownContent = [
  {
    id: 1,
    name: "Headache/Neck pain",
    choosed: false,
  },
  {
    id: 2,
    name: "Shoulder pain",
    choosed: false,
  },

  {
    id: 3,
    name: "Back pain",
    choosed: false,
  },
  {
    id: 4,
    name: "Elbow pain/Hand pain/Finger pain",
    choosed: false,
  },
  {
    id: 5,
    name: "Waist pain/Hip pain",
    choosed: false,
  },
  {
    id: 6,
    name: "Knee pain",
    choosed: false,
  },
  {
    id: 7,
    name: "Foot pain/Ankle pain",
    choosed: false,
  },
  {
    id: 8,
    name: "Neurological Condition",
    choosed: false,
  },
  {
    id: 9,
    name: "Pediatric Condition",
    choosed: false,
  },
  {
    id: 10,
    name: "Cardiological Condition",
    choosed: false,
  },
  {
    id: 11,
    name: "Others",
    choosed: false,
  },
];
export default DropdownContent;
