import PropTypes from "prop-types";
import "./DoctorInfoCard.css";

function DoctorInfoCard(props) {
  const bio = props.bio.map((text, index) => (
    <p key={index} style={{ marginBottom: "5px" }}>
      {text}
    </p>
  ));
  return (
    <>
      <div className="doctor-container">
        <div className="doctor-card">
          <img src={props.url} alt="Card image cap" />
          <div className="doctor-intro">
            <h1>{props.name}</h1>
            {bio}
          </div>
        </div>
      </div>
    </>
  );
}

DoctorInfoCard.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  bio: PropTypes.array,
};

export default DoctorInfoCard;
