import { useState } from "react";
import PropTypes from "prop-types";
import "./MobileVerification.css";
import Mobile from "../common/images/Mobile.png";
import India from "../common/images/INDIA.png";
import axios from "axios";

const MobileVerification = ({
  name,
  email,
  mobile,
  setMobile,
  setSendModal,
  setVerifyModal,
}) => {
  const [mobileError, setmobileError] = useState("");
  console.group(name, email, mobile);
  const handleSubmit = async (e) => {
    if (mobile === "") {
      setmobileError("Enter a Valid Mobile Number");
      return;
    }
    e.preventDefault();
    validateMobile();
    if (mobileError === "") {
      setSendModal(false);
      setVerifyModal(true);
      const userInfo = {
        name,
        email,
        mobile,
      };
      // Send Otp api called
      try {
        await axios.post("/api/users/login", userInfo);
      } catch (err) {
        setmobileError(err.response.data.message);
      }
    }
  };

  // Mobile number validation

  const validateMobile = () => {
    // var mobileNo = mobile.current.value;

    const phoneNo = /^\d{10}$/;
    if (
      !mobile.match(phoneNo) ||
      mobile[0] === "5" ||
      mobile[0] === "4" ||
      mobile[0] === "3" ||
      mobile[0] === "2" ||
      mobile[0] === "1" ||
      mobile[0] === "0"
    ) {
      setmobileError("Enter a Valid Mobile Number");
      return false;
    } else {
      setmobileError("");
    }
  };

  return (
    <>
      <div className="Main-Ver-container">
        <div className="Verification-container">
          <div className="ver-sub-container1">
            <img src={Mobile} alt="" className="mobile-img" />
            <button className="ver-btn" onClick={() => setSendModal(false)}>
              x
            </button>
          </div>
          <div className="ver-sub-container2">
            <div className="ver-mini-container">
              <h2>VERIFICATION</h2>
              <div className="ver-message">
                <p>Please enter your phone number to</p>
                <p>recieve a verification code</p>
              </div>
              <div className="input-container">
                <div className="input">
                  <img src={India} className="flag-img" alt="" />
                  <input
                    value={mobile}
                    type="text"
                    placeholder="Your Phone No"
                    className="code"
                    name="mobile"
                    required
                    onChange={(e) => setMobile(e.target.value)}
                    onBlur={validateMobile}
                  />
                </div>
                {mobileError !== "" && (
                  <p className="mobile-errormsg">{mobileError}</p>
                )}
              </div>

              <div className="ver-parent-button">
                <button className="ver-button" onClick={handleSubmit}>
                  GET OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MobileVerification.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  mobile: PropTypes.string,
  setMobile: PropTypes.any,
  setVerifyModal: PropTypes.any,
  setSendModal: PropTypes.any,
};
export default MobileVerification;
