import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./BookingPage.css";
import Doctor from "../components/common/images/Doc.png";
import Asset from "../components/common/images/Asset 4.png";
import NavBar from "../main-ui/nav-bar/NavBar";
import Footer from "../main-ui/footer/Footer";
import MobileFooter from "../main-ui/footer/MobileFooter";
import MobileVerification from "../components/mobile-verification/MobileVerification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "./Dropdown";
import MobileOtpVerify from "../components/mobile-otp-verify/MobileOtpVerify";
import axiosInstance from "../CustomAxios";

const BulkBookingPage = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("Profile"));
    if (user !== null) {
      setName(user?.data?.data?.name);
      setEmail(user?.data?.data?.email);
    }
  }, []);
  const [mobile, setMobile] = useState("");
  const [sendModal, setSendModal] = useState(false);
  const [, setVerified] = useState(false);
  const [nameError, setnameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [desError, setDesError] = useState("");
  const [quanError, setQuanError] = useState("");
  const [selected, setSelected] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [verifyModal, setVerifyModal] = useState(false);

  const validateName = () => {
    const result = /^[a-zA-Z ]+$/.test("John Doe");
    if (!result || document.bookingDetails.name.value === "") {
      setnameError("Enter a valid Name");
      return false;
    } else {
      setnameError("");
    }
    return true;
  };
  const validateEmail = () => {
    const uemail = document.bookingDetails.email.value;
    // if (uemail === "") {
    //   setemailError("");
    //   return true;
    // }
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!uemail.match(reg)) {
      setemailError("Enter a valid Email");
      return false;
    } else {
      setemailError("");
      return true;
    }
  };
  const validateDes = () => {
    if (selected === "") {
      setDesError("Select problem");
      return false;
    } else {
      setDesError("");
    }
    return true;
  };
  const validateQuantity = () => {
    if (quantity === "" || quantity <= 0) {
      setQuanError("Enter a valid quantity");
      return false;
    }
    setQuanError("");
    return true;
  };
  const validate = () => {
    if (
      validateName() &&
      validateDes() &&
      validateEmail() &&
      validateQuantity()
    ) {
      return true;
    }

    return false;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const user = JSON.parse(localStorage.getItem("Profile"));
      if (user !== null) {
        const User = await axiosInstance
          .get(`/api/users/${user.data.data.id}`)
          .catch((err) => {
            console.log(err);
          });
        const data = {
          name,
          email,
          patient_id: user.data.data.id,
          description: selected,
          new_user: user.data.data.new_user,
          price: User.data.data.price,
          authorization: user.headers.authorization,
        };
        history.push({
          pathname: "/confirmbooking",
          state: {
            bookingDetails: data,
            quantity: parseInt(quantity),
          },
        });

        return;
      }
      setSendModal(true);
    } else {
      // console.log("Not validated");
      // alert("Enter all details correctly!");
    }
  };
  return (
    <div className={verifyModal || sendModal ? "fixed" : ""}>
      <div className={verifyModal || sendModal ? "booking-blur" : ""}>
        <NavBar />
        <div className="book-main-container">
          <div className="book-left">
            <img className="book-asset-img" src={Asset} alt="" />
            <img className="book-doc-img" src={Doctor} alt="" />
          </div>

          <div className="book-right">
            <div className="book-mobile-header">
              <div className="book-right-arrow">
                <FontAwesomeIcon className="book-arrow" icon={faArrowRight} />
                <h2>Let's get the relief you've been waiting for.</h2>
              </div>
            </div>
            <div className="book-right-input">
              <form name="bookingDetails" className="booking-form" action="">
                <label className="book-input-field required">Name</label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  type="text"
                  required
                  onBlur={validateName}
                />
                {nameError !== "" && (
                  <span className="book-errormsg">{nameError}</span>
                )}
                <label htmlFor="email" className="book-input-field required">
                  E-mail
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  onBlur={validateEmail}
                />
                {emailError !== "" && (
                  <span className="book-errormsg">{emailError}</span>
                )}
                <label className="book-input-field required">Quantity</label>
                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  onBlur={validateQuantity}
                />
                {quanError !== "" && (
                  <span className="book-errormsg">{quanError}</span>
                )}
                <label className="required book-input-field">
                  Problem Description
                </label>
                <div style={{ marginBottom: "3rem" }}>
                  <Dropdown
                    selected={selected}
                    setSelected={setSelected}
                    setDesError={setDesError}
                  />
                  {desError !== "" && (
                    <span className="book-errormsg">{desError}</span>
                  )}
                </div>
                <div className="button-parent">
                  <button
                    type="submit"
                    className="book-button"
                    onClick={handleSubmit}
                  >
                    BOOK NOW
                  </button>
                </div>
              </form>
              <p style={{ color: "rgba(0,0,0,0.5)" }}>
                <span style={{ color: "red" }}>*</span>
                For first time users, amount payable is Rs. 499
              </p>
            </div>
          </div>
        </div>
      </div>
      {sendModal && (
        <MobileVerification
          name={name}
          email={email}
          mobile={mobile}
          setMobile={setMobile}
          setSendModal={setSendModal}
          setVerifyModal={setVerifyModal}
        />
      )}
      {verifyModal && (
        <MobileOtpVerify
          name={name}
          email={email}
          quantity={quantity}
          selected={selected}
          mobile={mobile}
          setVerified={setVerified}
          setVerifyModal={setVerifyModal}
        />
      )}
      <div className="desktop-footer">
        <Footer />
      </div>
      <div className="mobile-footer">
        <MobileFooter />
      </div>
    </div>
  );
};

export default BulkBookingPage;
