import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { useState } from "react";
import { faSpinner, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrow from "./arrow.png";
import NavBar from "../main-ui/nav-bar/NavBar";
import "./ConfirmPhysio.css";
import Footer from "../main-ui/footer/Footer";
import MobileFooter from "../main-ui/footer/MobileFooter";
import { bucket } from "./../utils";
import axiosInstance from "../CustomAxios";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
const __ISPROD__ =
  window.location.hostname === "physifit.org" ||
  window.location.hostname === "www.physifit.org";

function ConfirmPhysio(props) {
  const bookingDetails = props.location.state.bookingDetails;
  const user = JSON.parse(localStorage.getItem("Profile"));
  const booking = {
    patient_id: bookingDetails.patient_id,
    quantity: parseInt(bookingDetails.quantity),
    coupon_code: bookingDetails.coupon ? bookingDetails.coupon.coupon_code : "",
    price: bookingDetails.price,
  };
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handlePayButton = (e) => {
    setIsLoading(true);
    e.preventDefault();
    handleRazorPay();
  };
  const handleRazorPay = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    try {
      const req = {
        name: user.data.data.name,
        email: user.data.data.email,
        patient_id: user.data.data.id,
        quantity: parseInt(bookingDetails.quantity),
        price: bookingDetails.price,
      };
      const { data } = await axiosInstance.post("/api/orders", req);
      const options = {
        key: __ISPROD__ ? "rzp_live_7dZ4TX7Ab409fl" : "rzp_test_NseEJwKl04eSmh", // Enter the Key ID generated from the Dashboard
        amount: data.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: data.data.currency,
        name: "Physifit", // your business name
        description: "Booking Transaction",
        image:
          "https://storage.googleapis.com/" +
          bucket +
          "/physifit-ui/assets/Social-Media-Icon.png",
        callback_url: `/thankyou?email=${data.email}`,

        order_id: data.data.id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        prefill: {
          name: data.data.name,
          email: data.data.email,
          contact: bookingDetails.patient_id,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", function (response) {
        history.push("/paymentfailed");
      });
      paymentObject.open();
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      alert("Wrong Patient ID");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <NavBar />
      <section className="page-header">
        <div className="page-header-content">
          <img src={arrow} alt="arrow" className="page-header-img" />
          <span className="page-header-text">CONFIRM YOUR BOOKING</span>
        </div>
      </section>
      <div className="confirm-details">
        <div className="confirm-details-element">
          <p className="confirm-details-element-label">Patient's ID</p>
          <p className="confirm-details-element-text">
            {bookingDetails.patient_id}
          </p>
        </div>
        <div className="confirm-details-element">
          <p className="confirm-details-element-label">Quantity</p>
          <p className="confirm-details-element-text">{booking.quantity}</p>
        </div>
        {booking.coupon_code !== "" && (
          <div className="confirm-details-element">
            <p className="confirm-details-element-label">Coupon Applied</p>
            <p className="confirm-details-element-text">
              {booking.coupon_code}
            </p>
          </div>
        )}

        <div className="confirm-details-amount">
          <span className="booking-amt">
            <span className="booking-amt-label">Booking Amount :</span>
            <FontAwesomeIcon icon={faRupeeSign} />
            &nbsp;
            <span className="booking-amt-text">{bookingDetails.amount}</span>
          </span>
        </div>
        <div className="booknow-parent">
          <div className="booknow-child">
            {!isLoading && (
              <button
                onClick={handlePayButton}
                type="submit"
                className="btn-booking"
              >
                CONFIRM BOOKING
              </button>
            )}
            {isLoading && (
              <button className="btn-booking" disabled>
                <FontAwesomeIcon icon={faSpinner} className="icon-spinner" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="desktop-footer">
        <Footer />
      </div>
      <div className="mobile-footer">
        <MobileFooter />
      </div>
    </div>
  );
}

ConfirmPhysio.propTypes = {
  location: PropTypes.object,
};
export default ConfirmPhysio;
