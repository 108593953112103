import axios from "axios";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import store from "./redux/Store";
import { setAccessToken } from "./redux/Action";

const state = store.getState();

const accessToken = state.access_token;

const axiosInstance = axios.create({
  baseURL: "",
  timeout: 120000,
  headers: {
    authorization: accessToken,
  },
});

axiosInstance.interceptors.request.use(async (req) => {
  if (accessToken !== "") {
    const user = jwtDecode(accessToken);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    if (!isExpired) return req;
  }
  try {
    const res = await axios.post("/api/token/refresh");
    store.dispatch(setAccessToken(res.data.data));
  } catch (err) {
    if (err.response.status === 401) {
      const doctor = JSON.parse(localStorage.getItem("Doctor"));
      localStorage.clear();
      if (doctor === null) {
        localStorage.setItem("PreviousLogger", "patient");
        window.location.href = "/user/login";
      } else {
        localStorage.setItem("PreviousLogger", "doctor");
        window.location.href = "/dashboard/login";
      }
    }
  }

  req.headers.authorization = store.getState().access_token;

  return req;
});

export default axiosInstance;
