import { bucket } from "./../../utils";
const TestimonialData = [
  {
    id: 1,
    text: "The physiotherpist was very helpful and accommodating for my knee injury during the lockdown and helped me recover quickly while taking good care of me. Would highly recommend for any sports or other physio related injury.",
    name: "Mr. Rushail",
    designation: "Lawyer",
    img:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/testimonials/Image_2.png",
  },
  {
    id: 2,
    text: "I, suffer from RA and vertigo. I was helped to move on with my life with the right exercises and most important motivating words. Thank you doctor for easing my pain and encouraging me when I was down. For being so humble and ready to help. Thanks for being there for me.",
    name: "Mrs Sarah D'souza",
    designation: "Homemaker",
    img:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/testimonials/Image_1.png",
  },
  //   {
  //     id: 3,
  //     text: "I had a brain stroke and couldn't move my legs. However, with the right exercises and training I’m back to walking normally and leading my normal life. Thank you for getting me back on my feet",
  //     name: "Mr Shetty",
  //     designation: "Businessman",
  //     img: "https://storage.googleapis.com/"+bucket+"/physifit-ui/assets/testimonials/Image_3.png",
  //   },
  {
    id: 3,
    text: "I'm a 73-year-old dialysis patient with diabetic neuropathy and a bypass in 2011. I was referred physiotherapy by my neurophysician. Following my consultation, I have been taught to walk without my walker, how to get up by myself in case of a fall. I felt that there was an overall improvement in my confidence level and could take up challenges in my movements.", // I say this because there was always a fear of fall at the back of my mind. I look forward to at least taking your advice on and off.",
    name: "Mr. Ramesh Sitlani",
    designation: "Retired Tax Officer",
    img:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/testimonials/Image_3.png",
  },
  {
    id: 4,
    // "Because God could not be everywhere, he made sure that we have doctors like you, who have a gift of healing mankind.
    text: "I suffered a Brain stroke and was unable to move the right side of my body. With the help of physiotherapy I was able to walk within a month. I am so thankful for treating me tenderly that I feel very healthy now and I could resume my office.",
    // Thank you Dr Susan D'Souza... God bless you for all your future endeavours.. Take care"
    name: "Mr. Caesar",
    designation: "Service",
    img:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/testimonials/Image_4.png",
  },
  {
    id: 5,
    text: "I was suffering from severe back pain and shoulder pain, and it was very difficult for me to go to the hospital for my physio. With  physiotherapy I was able to take online sessions without taking leave from my office. The session was extremely helpful, I was able to recover completely.",
    name: "Prince Kumar",
    designation: "Software Engineer",
    img:
      "https://storage.googleapis.com/" +
      bucket +
      "/physifit-ui/assets/testimonials/Image_5.png",
  },
];

export default TestimonialData;
