import PropTypes from "prop-types";
import Countdown from "react-countdown";
import CalendarImage from "../images/Calendar-Icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";

function PaymentSuccessPhysio(props) {
  const orderID = props.location.state.orderID;
  const paymentID = props.location.state.paymentID;

  const bookingDetails = props.location.state.data;

  setTimeout(function () {
    window.location.replace("/");
  }, 10000);

  const renderer = ({ seconds }) => {
    // Render a countdown
    return <span>{seconds}</span>;
  };

  return (
    <div className="paymentsuccess">
      <div className=" mt-3 d-flex  justify-content-center">
        <img
          height="100"
          width="100"
          src={CalendarImage}
          alt="Calendar_Image"
        />
      </div>

      <h4 className="text-center fw-bold">Thanks for booking ! </h4>
      <p className="text-center fw-light text-muted">
        You'll Get an email Along with Your booking Confirmation
      </p>
      <div className="conf-booking">
        <div className="d-flex">
          <p>Order ID :</p>
          <h6>{orderID}</h6>
        </div>

        <div className="d-flex">
          <p>Patient ID : </p>
          <h6>{bookingDetails.patientID}</h6>
        </div>

        <div className="d-flex">
          <p>Payment ID : </p>
          <h6>{paymentID}</h6>
        </div>

        <div className="d-flex">
          <p>Amount : </p>

          <h6>
            <FontAwesomeIcon icon={faRupeeSign} />
            &nbsp;
            {bookingDetails.coupon
              ? bookingDetails.amount -
                (bookingDetails.amount *
                  bookingDetails.coupon.discountpercentage) /
                  100
              : bookingDetails.amount}
          </h6>
        </div>

        <h2 className="mt-5">
          Redirecting in{" "}
          <Countdown date={Date.now() + 10000} renderer={renderer} /> seconds
        </h2>
      </div>
    </div>
  );
}

PaymentSuccessPhysio.propTypes = {
  location: PropTypes.object,
};
export default PaymentSuccessPhysio;
