// import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import {
  MdAccountBalanceWallet,
  //   MdOutlineKeyboardArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";
import "./DoctorProfile.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../CustomAxios";
// import { monthsShort } from "moment";
const DoctorProfile = () => {
  const [doctorInfo, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    gender: "",
    dob: "",
  });
  const date = new Date(Date.now());
  const totalDays = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
  const startDate =
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    "01";
  const endDate =
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    totalDays;

  const [payoutAmount, setPayoutAmount] = useState(0);
  const location = useLocation();
  const doctorId = location.state.id;
  const fetchPayoutAmount = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/api/appointments/${doctorId}/sum`
      );
      if (data) {
        setPayoutAmount(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDoctorProfile = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/doctors/${doctorId}`);

      if (data) {
        setData({
          name: data.data.name,
          email: data.data.email,
          mobile: data.data.mobile,
          gender: data.data.gender,
          dob: data.data.birth_date,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchDoctorProfile();
    fetchPayoutAmount();
  }, []);

  const propsData = {
    id: doctorId,
    amount: payoutAmount,
    name: doctorInfo.name,
    startDate,
    endDate,
  };

  const history = useHistory();

  return (
    <div>
      <div className="main-container">
        <div className="dprofile-navbar">
          <div
            className="arrow-container"
            onClick={() => {
              history.goBack();
            }}
          >
            <FaArrowLeft size="1.5rem" color="#14a6c3" />
          </div>
          <div className="profile-heading">My Profile</div>
          <div>
            <FiEdit size="1.5rem" color="teal" />
          </div>
        </div>
        <div className="payout">
          <Link
            className="none"
            to={{
              pathname: `/doctor/${doctorId}/payout`,
              state: { data: propsData },
            }}
          >
            <div className="payout-details">
              <div>
                <MdAccountBalanceWallet size="2rem" />
              </div>
              <div>Payout Balance (Rs)</div>
              <div>{payoutAmount}</div>
              <div className="next-arrow">
                <div className="animated-arrow sliding">
                  <div className="right-arrow1 ">
                    {" "}
                    <MdKeyboardArrowRight />
                  </div>
                  <div className="right-arrow2 ">
                    <MdKeyboardArrowRight />
                  </div>
                  <div className="right-arrow3 ">
                    <MdKeyboardArrowRight />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="doctor-profile">
          <div className="doctor-profile-info">
            <div>
              <label htmlFor="name">Name</label>
              <input disabled value={doctorInfo.name} id="name" type="text" />
            </div>

            <div>
              <label htmlFor="email">Email</label>
              <input
                disabled
                value={doctorInfo.email}
                id="email"
                type="email"
              />
            </div>
            <div>
              <label htmlFor="mobile">Mobile</label>
              <input
                disabled
                id="mobile"
                type="text"
                value={doctorInfo.mobile}
                // placeholder="Enter your mobile no"
              />
            </div>
            <div>
              <label htmlFor="gender">Gender</label>
              <select
                disabled
                value={doctorInfo.gender}
                id="gender"
                type="dropdown"
              >
                <option value=""></option>
                <option value="male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div>
              <label htmlFor="dob">Date of Birth</label>
              <input
                disabled
                value={doctorInfo.dob}
                id="dob"
                type="date"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;
