import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const ProtectedUserRoutes = ({ children, ...rest }) => {
  const user = JSON.parse(localStorage.getItem("Profile"));
  const doctor = JSON.parse(localStorage.getItem("Doctor"));
  const isAuth = user || doctor;
  const previousLogger = localStorage.getItem("PreviousLogger");
  const redirectURL = () => {
    return previousLogger === null || previousLogger === "patient"
      ? "/user/login"
      : "/dashboard/login";
  };
  return (
    <Route
      {...rest}
      render={() => (isAuth ? children : <Redirect to={redirectURL} />)}
    />
  );
};

ProtectedUserRoutes.propTypes = {
  children: PropTypes.any,
};
export default ProtectedUserRoutes;
