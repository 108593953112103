import PropTypes from "prop-types";
import Countdown from "react-countdown";

import CalendarImage from "../images/Calendar-Icon.png";

function PaymentSuccess(props) {
  const orderID = props.location.state.orderID;

  const bookingDetails = props.location.state.bookingDetails;

  setTimeout(function () {
    window.location.replace("/");
  }, 10000);

  const renderer = ({ seconds }) => {
    // Render a countdown
    return <span>{seconds}</span>;
  };

  return (
    <div className="paymentsuccess">
      <div className=" mt-3 d-flex  justify-content-center">
        <img
          height="100"
          width="100"
          src={CalendarImage}
          alt="Calendar_Image"
        />
      </div>

      <h4 className="text-center fw-bold">Thanks for booking ! </h4>
      <p className="text-center fw-light text-muted">
        You'll get an email along with your booking confirmation
      </p>
      <div className="conf-booking">
        <div className="d-flex">
          <p>Order ID :</p>
          <h6>{orderID}</h6>
        </div>

        <div className="d-flex">
          <p>Date :</p>
          <h6>{bookingDetails.appointmentDate}</h6>
        </div>

        <div className="d-flex">
          <p>Time :</p>
          <h6>{bookingDetails.appointmenttime}</h6>
        </div>

        <h2 className="mt-5">
          Redirecting in{" "}
          <Countdown date={Date.now() + 10000} renderer={renderer} /> seconds
        </h2>
      </div>
    </div>
  );
}

PaymentSuccess.propTypes = {
  location: PropTypes.object,
};
export default PaymentSuccess;
