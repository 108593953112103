import "./HomeContent.css";
import MedicalSign from "../../images/Medical_Sign.png";
export default function HomeContent() {
  return (
    <>
      <div className="home-content">
        <div className="home-content-img"></div>
        <div className="home-content-body">
          <div className="home-content-body-container">
            <div className="home-content-text">
              <img
                src={MedicalSign}
                alt="Medical Sign"
                className="home-medical-sign"
              ></img>
              <p>
                Get back your active life by finding the relief you've been
                waiting for
                <br /> Join us in keeping fit and getting better everyday
              </p>
            </div>
            <div className="home-container-btn-parent home-btn-div">
              <div className="home-content-btn">
                <button type="button" className="btn-booking">
                  <a
                    href="/booking"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Book Appointment
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
