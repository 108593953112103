import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import { useDispatch } from "react-redux";

import "./MobileOtpVerify.css";
import Mobile from "../common/images/Mobile.png";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { setAccessToken } from "../../redux/Action";
import axios from "axios";

const MobileOtpVerify = ({
  mobile,
  name,
  email,
  selected,
  setVerified,
  setVerifyModal,
  quantity,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpError, setOtpError] = useState("");
  const [counter, setCounter] = useState(15);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  const handleKeyDown = (event) => {
    const key = event.keyCode || event.charCode;
    if (key === 8) {
      if (event.target.value === "") {
        if (event.target.previousSibling) {
          event.target.previousSibling.focus();
        }
      }
    }
  };
  const dispatch = useDispatch();
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      if (element.value !== "") element.nextSibling.focus();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const verifyData = {
      number: mobile,
      otp: otp.join(""),
    };
    try {
      setIsLoading(true);
      const res = await axios.post("/api/users/login/verify", verifyData);
      if (res.status === 200) {
        localStorage.setItem("Profile", JSON.stringify(res));
        dispatch(setAccessToken(res.headers.authorization));
        Cookie.set("refresh", res.headers.refreshtoken);
        setVerified(true);
        setOtpError("");
        const newData = {
          name: res.data.data.name !== "" ? res.data.data.name : name,
          email: res.data.data.email !== "" ? res.data.data.email : email,
          patient_id: res.data.data.id,
          description: selected,
          new_user: res.data.data.new_user,
          price: res.data.data.price,
          authorization: res.headers.authorization,
        };
        setIsLoading(false);
        setVerifyModal(false);
        // If new user is there then payment section is bypassed and directed to ThankyouPage.
        history.push({
          pathname: "/confirmbooking",
          state: {
            bookingDetails: newData,
            quantity: parseInt(quantity),
          },
        });
      } else {
        setOtpError("Wrong OTP!");
        setIsLoading(false);
        return false;
      }
    } catch (err) {
      setOtpError("Wrong OTP!");
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="Main-Ver-container">
        <div className="Verification-container">
          <div className="ver-sub-container1">
            <img src={Mobile} alt="" className="mobile-img" />
            <button className="ver-btn" onClick={() => setVerifyModal(false)}>
              x
            </button>
          </div>
          <div className="ver-sub-container2">
            <div className="ver-mini-container">
              <h2>VERIFICATION CODE</h2>
              <div className="ver-message">
                <p>Please enter the verification code sent</p>
                <p>to your mobile number and email.</p>
              </div>
              <div className="vinput-container">
                {otp.map((data, index) => {
                  return (
                    <input
                      type="tel"
                      className="otpVal"
                      name="otp"
                      maxLength={1}
                      key={index}
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                      onKeyDown={(e) => handleKeyDown(e)}
                      autoFocus={index === 0}
                    />
                  );
                })}
              </div>
              {otpError !== "" && (
                <p className="mobile-verifyerror">{otpError}</p>
              )}
              {/* <div className="resend-otp">
                {counter === 0 ? (
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setCounter(15);
                    }}
                  >
                    Resend Code?
                  </a>
                ) : (
                  <p>Resend Otp in {counter} sec</p>
                )}
              </div> */}
              <div className="ver-parent-button">
                {!isLoading && (
                  <button className="ver-button" onClick={handleSubmit}>
                    VERIFY
                  </button>
                )}
                {isLoading && (
                  <button className="ver-button" disabled>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="icon-spinner"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MobileOtpVerify.propTypes = {
  mobile: PropTypes.string,
  name: PropTypes.string,
  source: PropTypes.string,
  quantity: PropTypes.number,
  email: PropTypes.string,
  selected: PropTypes.string,
  setVerified: PropTypes.any,
  setVerifyModal: PropTypes.any,
};
export default MobileOtpVerify;
