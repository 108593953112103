import PropTypes from "prop-types";
import "./ConditionCard.css";

function ConditionCard(props) {
  const handleClick = () => {
    window.location = props.link;
  };
  return (
    <div className="condition">
      <div className="condition-card m-4" onClick={handleClick}>
        <img
          className="condition-card-image"
          src={`${props.condition}`}
          alt="condition card image"
        />
        <div className="condition-card-content font-change">
          {props.description}
        </div>
      </div>
    </div>
  );
}

ConditionCard.propTypes = {
  condition: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};
export default ConditionCard;
