import { Component } from "react";
import axiosInstance from "../CustomAxios";
import PropTypes from "prop-types";
import "./BookingPhysio.css";
import arrow from "./arrow.png";
import NavBar from "../main-ui/nav-bar/NavBar";
import Footer from "../main-ui/footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import MobileFooter from "../main-ui/footer/MobileFooter";
import DeleteIcon from "@mui/icons-material/Delete";

export default class BookingPhysio extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (this.state.patientID.length !== 10) {
      alert("Patient ID is not Registered.");
      this.props.history.push({
        pathname: "/booking",
      });
      return;
    }
    const user = JSON.parse(localStorage.getItem("Profile"));
    if (!user) {
      alert("You have to login first!");
      this.props.history.push({
        pathname: "/user/login",
        state: { origin: "book-physio" },
      });
      return;
    }
    if (user.data.data.new_user === false) {
      this.setState({ price: user.data.data.price });
      this.setState({ quantity: user.data.data.sessions });
      this.setState({ amount: user.data.data.price * user.data.data.sessions });
      this.setState({
        initialPrice: user.data.data.price * user.data.data.sessions,
      });
    } else {
      alert("Patient ID is not Registered.");
      this.props.history.push({
        pathname: "/booking",
      });
    }
  }

  state = {
    patientID: this.props.match.params.id,
    quantity: 0,
    amount: 0,
    price: 0,
    coupons: [],
    currentCoupon: null,
    couponError: "",
    isLoading: false,
    promo: "",
    isApplied: false,
    initialPrice: 0,
    applyLoad: false,
  };

  // handleIDChange = (e) => {
  //   const patientID = e.target.value;
  //   this.setState({ patientID });
  // };

  setCouponError = (value) => {
    this.setState({ couponError: value });
  };

  // handleQuantityChange = (e) => {
  //   document.getElementById("payBtn").disabled = false;
  //   const quantity = e.target.value;
  //   this.setState({ quantity });
  //   this.setState({ amount: quantity * this.state.price });
  // };

  handleRemove = () => {
    this.setState({ price: 499 });
    this.setState({ promo: "" });
    this.setState({ isApplied: false });
  };

  validateCoupon = async (e) => {
    e.preventDefault();
    if (this.state.promo !== "") {
      try {
        this.setState({ applyLoad: true });
        const response = await axiosInstance.get("/api/dashboard/coupons", {
          params: {
            coupon_code: this.state.promo,
          },
        });
        const { data } = response.data;
        if (!data || data.status === "inactive") {
          this.setCouponError("Invalid Coupon");
          this.setState({ applyLoad: false });
          return;
        }
        this.setState({
          couponError: null,
          currentCoupon: data,
        });
        this.setState({
          amount:
            (Math.round(
              (this.state.price -
                (this.state.price *
                  this.state.currentCoupon.discount_percentage) /
                  100) *
                100
            ) /
              100) *
            this.state.quantity,
        });
        this.setState({ isApplied: true });
        this.setState({ applyLoad: false });
      } catch (e) {
        console.log(e);
        this.setCouponError("Invalid Coupon");
        this.setState({ applyLoad: false });
      }
    }
  };

  render() {
    const handleClick = async (e) => {
      if (this.patientID === "" || this.state.quantity === "") {
        return;
      }
      e.preventDefault();
      const data = {
        patient_id: this.state.patientID,
        quantity: this.state.quantity,
        amount: this.state.amount,
        price: this.state.currentCoupon
          ? (this.state.price *
              (100 - this.state.currentCoupon.discount_percentage)) /
            100
          : this.state.price,
        coupon: this.state.currentCoupon,
      };
      this.props.history.push({
        pathname: "/confirmbookingphysio",
        state: {
          bookingDetails: data,
        },
      });
    };
    return (
      <>
        <NavBar />
        <section className="page-header">
          <div className="page-header-content">
            <img src={arrow} alt="arrow" className="page-header-img" />
            <span className="page-header-text">BOOK PHYSIOTHERAPY SESSION</span>
          </div>
        </section>
        <section className="book-physio-container">
          <form className="book-physio-form">
            <input
              value={this.state.patientID}
              // onChange={this.handleIDChange}
              name="patientID"
              type="text"
              placeholder="Patient ID"
              className="book-physio-input"
              readOnly
              required
            />
            <input
              value={this.state.quantity}
              // onChange={this.handleQuantityChange}
              type="number"
              placeholder="Quantity"
              className="book-physio-input"
              readOnly
              required
            />
            <section className="promo-code-box">
              <section className="promo-code">
                <label htmlFor="product" className="center-align">
                  Apply a Promo Code
                </label>
                <input
                  id="promo-code-box"
                  type="text"
                  name="product"
                  list="productName"
                  value={this.state.promo}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      this.setState({ currentCoupon: null, promo: "" });
                      this.handleRemove();
                    } else {
                      this.setState({ promo: e.target.value.toUpperCase() });
                    }
                  }}
                  onFocus={() => this.setState({ couponError: "" })}
                />
                {/* <datalist id="productName">
                                    {this.state.coupons.map((item) => {
                                        return (
                                            <option key={item.couponcode} value={item.couponcode}>
                                                {item.couponcode}
                                            </option>
                                        );
                                    })}
                                </datalist> */}
                {!this.state.applyLoad &&
                  !this.state.isApplied &&
                  this.state.promo && (
                    <button
                      onClick={this.validateCoupon}
                      className="btn-booking btn-booking-promo-validate bookphysio-desktop"
                    >
                      Apply
                    </button>
                  )}
                {!this.state.applyLoad &&
                  !this.state.isApplied &&
                  this.state.promo && (
                    <button
                      onClick={this.validateCoupon}
                      className="btn-booking btn-booking-promo-validate bookphysio-mobile"
                    >
                      Apply Promo
                    </button>
                  )}
                {this.state.applyLoad && (
                  <button className="btn-booking" disabled>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="icon-spinner"
                    />
                  </button>
                )}
                {this.state.isApplied && (
                  <button
                    onClick={this.handleRemove}
                    style={{ width: "fit-content", marginLeft: "10px" }}
                    className="btn-booking"
                  >
                    <DeleteIcon />
                  </button>
                )}
              </section>

              {this.state.couponError && (
                <div className="text-danger font-weight-bold coupon-error">
                  {this.state.couponError}
                </div>
              )}
              {this.state.currentCoupon && (
                <span style={{ color: "green" }}>Applied Successfully</span>
              )}
            </section>

            <span className="booking-amt">
              <FontAwesomeIcon icon={faRupeeSign} />
              &nbsp;
              {this.state.isApplied ? (
                <>
                  <span
                    style={{ textDecoration: "line-through" }}
                    className="booking-amt-text"
                  >
                    {this.state.initialPrice}
                  </span>
                  <span>
                    <strong>
                      &emsp;
                      {this.state.amount}
                    </strong>
                  </span>
                </>
              ) : (
                <span className="booking-amt-text">
                  {this.state.initialPrice}
                </span>
              )}
            </span>
            <div className="bookphysio-booking-container">
              <div className="bookphysio-booking-btn-parent">
                <div className="bookphysio-booking-btn">
                  {!this.state.isLoading && (
                    <button
                      id="payBtn"
                      onClick={handleClick}
                      type="submit"
                      className="btn-booking"
                    >
                      BOOK NOW
                    </button>
                  )}
                  {this.state.isLoading && (
                    <button className="btn-booking" disabled>
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="icon-spinner"
                      />
                    </button>
                  )}
                </div>
              </div>
              <span className="mt-3 text-center">
                Do not have a patient id? <a href="/booking">Click Here</a>
              </span>
            </div>
          </form>
        </section>
        <div className="desktop-footer">
          <Footer />
        </div>
        <div className="mobile-footer">
          <MobileFooter />
        </div>
      </>
    );
  }
}

BookingPhysio.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};
