import CalendarImage from "../images/Calendar-Icon.png";

function PaymentFailed() {
  return (
    <div>
      <div className="mt-5">
        <div className="d-flex  justify-content-center">
          <img
            height="100"
            width="100"
            src={CalendarImage}
            alt="Calenar_Image"
          />
        </div>

        <h4 className="text-center text-danger fw-bold mt-5">
          Payment Failed{" "}
        </h4>

        <div className="conf-booking"></div>
      </div>
    </div>
  );
}

export default PaymentFailed;
