import ServiceCard from "./service-card/ServiceCard";
import images from "../images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./OurProcedure.css";
const OurProcedure = () => {
  const settings = {
    className: "service-carousel",
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <div>
        <Slider {...settings}>
          <ServiceCard src={images.process1} alt="Consultation" />
          <ServiceCard
            src={images.process3}
            alt="Planning of Future Sessions"
          />
          <ServiceCard src={images.process2} alt="Treatment Course" />
          <ServiceCard src={images.process4} alt="Follow up and Review" />
        </Slider>
      </div>
    </>
  );
};

export default OurProcedure;
